import { faArrowUp, faCopy, faUpload, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import config from '../config/config';
import FileUpload from './FileUpload';
import SidebarProfilesCard from './SidebarProfilesCard';
import { copyToClipboard } from '../utils/commons';

const Modal = ({ children, onClose }) => {
    const [showOption, setShowOption] = useState();
    const [imageUrl, setImageUrl] = useState();

    const handleProfileClick = (content) => {
        copyToClipboard(content);
      };

  return (
    <div className="fixed z-10 inset-0">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div className="inline-block overflow-y-auto max-h-80vh slim-scrollbar align-bottom bg-body border border-primary rounded text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-full lg:w-1/2">
        
        <div className='flex flex-row gap-4 p-2'>
                <button className='flex flex-row text-center p-2 bg-body hover:bg-gray-700 hover:text-white border border-primary text-primary rounded w-full justify-center items-center' onClick={() => setShowOption("profiles")}>
                    <FontAwesomeIcon icon={faUser} className="mr-2" />Profiles</button>
                <button className='flex flex-row text-center p-2 bg-body hover:bg-gray-700 hover:text-white border border-primary text-primary rounded w-full justify-center items-center' onClick={() => setShowOption("upload")}>
                    <FontAwesomeIcon icon={faUpload} className="mr-2" />Upload</button>
                {showOption && <button className='flex flex-row text-center p-2 bg-body hover:bg-gray-700 hover:text-white border border-primary text-primary rounded w-full justify-center items-center' onClick={() => setShowOption()}>
                    <FontAwesomeIcon icon={faArrowUp} className="" /></button>
                }
                <button
                    className="text-3xl hover:text-gray-500"
                    onClick={onClose}
                    >
                    &times;
                    </button>
            </div>
          {showOption && <div className="flex flex-row w-full hidden xl:block p-4 border border-primary mt-2 md:mt-0 overflow-auto rounded">
                {showOption === "profiles" && (
                  <SidebarProfilesCard
                    onProfileClick={handleProfileClick}
                    header={'Click a profile to copy its contents to your clipboard.'}
                  />
                )}
                {showOption === "upload" && (
                  <div>
                    <h2 className="text-center text-poetsen text-primary">Upload an Image/File</h2>
                    <FileUpload
                      uploadUrl={`${config.apiUrl}/admin/upload`}
                      onSuccess={(url) => setImageUrl(url)}
                    />
                    {imageUrl && (<div className="flex flex-row justify-between w-full gap-4">
                      <img src={imageUrl} alt="Uploaded" className="w-12 h-auto" />
                      <button className='flex flex-row text-center p-2 bg-body border border-primary text-primary rounded w-full justify-center items-center' onClick={() => copyToClipboard(imageUrl)}>
                        <FontAwesomeIcon icon={faCopy} className="mr-2" />Copy URL</button>
                      </div>)}
                  </div>
                )}
              </div>}
          <div className="bg-body px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            {children}
          </div>
          <div className="bg-body px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded shadow-sm px-4 py-2 bg-primary text-base font-medium hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={onClose}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
