// useScreenSize.js
import { useMediaQuery } from 'react-responsive';

// Define breakpoints
const size = {
    tablet: 768,
    laptop: 1024,
    desktop: 1224,
};

export const useIsMobileOrTablet = () => {
    const isTabletOrBelow = useMediaQuery({ maxWidth: size.laptop - 1 });
    return isTabletOrBelow;
};

export const useIsLaptopOrDesktop = () => {
    const isLaptopOrAbove = useMediaQuery({ minWidth: size.laptop });
    return isLaptopOrAbove;
};
