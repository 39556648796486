import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { hideModalAPI } from '../features/ui/uiSlice';
import { selectSession } from '../features/session/sessionSlice';
import { addToast } from '../features/ui/uiSlice';
import { updateBYOKkey } from '../api/userFunctions';
import { updateServiceState } from '../features/byokUserAPIKeys/BYOKUserAPIKeysSlice';

const ModalAPI = () => {
  const [apiKey, setApiKey] = useState('');
  const dispatch = useDispatch();
  const modal = useSelector((state) => state.ui.modalAPI);
  const session = useSelector(selectSession);
  
  if (!modal.isVisible) return null;

  const handleCancel = () => {
    dispatch(hideModalAPI());
  };

  const handleUpdateKey = async () => {
    const payload = {
        api_service: modal.service,
        api_key: apiKey.trim(),
        dynamic_id: modal.user,
        action: modal.action,
    };
    
    try {
        const updateBYOKkeyResponse = await updateBYOKkey(payload, session);
        console.log('payload:', payload);
    } catch (error) {
        console.error('Error updating API key:', error.response ? error.response.data.message : error.message);
    }
};

  const handleDelete = () => {
    handleUpdateKey();
    dispatch(addToast('API key deleted successfully', 'success'));
    dispatch(updateServiceState({ service: modal.service, newState: false }));
    dispatch(hideModalAPI());
  };

  const handleAdd = () => {
    handleUpdateKey();
    dispatch(addToast('API key added successfully', 'success'));
    dispatch(updateServiceState({ service: modal.service, newState: true }));
    dispatch(hideModalAPI());
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-10 rounded text-black text-center">
        <h2 className="text-center mb-4">{modal.service_name}</h2>
        {modal.action === 'delete' && (
          <>
            <p><b>Are you sure you want to delete this API key?</b></p>
            <button className="bg-red-400 hover:bg-red-600 rounded p-3 mt-10 font-bold" onClick={handleDelete}>Confirm</button>
            <button className="bg-gray-300 hover:bg-gray-400 rounded p-3 mt-10 ml-2" onClick={handleCancel}>Cancel</button>
          </>
        )}
        {modal.action === 'add' && (
            <div className="flex flex-col items-center">
                <input type="text" value={apiKey} onChange={(e) => setApiKey(e.target.value)} className="border border-gray-600 p-1 rounded w-full" placeholder="Enter API Key" />
                <div className="mt-10 flex justify-center w-full">
                    <button className="bg-green-400 hover:bg-green-600 rounded p-3 font-bold" onClick={handleAdd}>Submit</button>
                    <button className="bg-gray-300 hover:bg-gray-400 rounded p-3 ml-2" onClick={handleCancel}>Cancel</button>
                </div>
                <div className="flex flex-col rounded border mt-8 bg-gray-200 p-4">
                    <p>Your API keys are securely stored and encrypted. You can add or remove API keys at any time.</p>
                    <p className='mt-2'>After saving an API key, for security purposes you will NOT be able to view it within this app.</p>
                </div>
            </div>
        )}
      </div>
    </div>
  );
};

export default ModalAPI;
