import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import config from "../config/config";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectUser, setUser, reduceAICredits } from "../features/user/userSlice";
import { selectSession } from "../features/session/sessionSlice";
import { setThreadId, selectCurrentThreadId, updateThreadId, deleteThreadId } from "../features/threads/threadSlice";
import { selectCurrentTool } from "../features/currentTool/currentToolSlice";
import { openModal } from "../features/modals/jsonModalSlice";
import { selectCurrentProfile } from "../features/currentTool/currentProfileSlice";
import { generateChat, postMessage } from "../api/aiFunctions";
//import { selectCurrentPrompt, setResponse, setLoading, setError, setCurrentPrompt } from '../features/chat/chatSlice';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRotateRight, faCopy, faPaperPlane, faEdit, faAddressBook, faUpload } from "@fortawesome/free-solid-svg-icons";
import socket from "../utils/socket";
import { copyToClipboard } from "../utils/commons";
import { addToast } from "../features/ui/uiSlice";
//import SavePrompt from './SavePrompt';
//import ModalPromptSave from './ModalPromptSave';
import markdownit from "markdown-it";
import hljs from "highlight.js";
import 'highlight.js/styles/monokai.css';
import markdownItMermaid from "markdown-it-mermaid";
import taskLists from "markdown-it-task-lists";
import mdVideos from "markdown-it-video";
//import miliMarkdown from "markdown-it-linkify-images";
import { full as emoji } from "markdown-it-emoji";
import { setCurrentTool } from "../features/currentTool/currentToolSlice";
import { faStar as solidStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as regularStar } from "@fortawesome/free-regular-svg-icons";
import SidebarProfilesCard from "./SidebarProfilesCard";
import BoardSelectorButton from "./BoardSelectorButton";
import FileUpload from "./FileUpload";
import { setCurrentThreadMessages } from "../features/threads/threadHistorySlice";

const ChatComponent = () => {
  const dispatch = useDispatch();
  const apiURL = config.apiUrl;
  const tools = useSelector((state) => [
    ...state.tools.items.tools,
    ...state.tools.items.assistants,
  ]);

  const user = useSelector(selectUser);
  const session = useSelector(selectSession);
  const currentTool = useSelector(selectCurrentTool);
  const textareaRef = useRef(null);
  const currentProfile = useSelector(selectCurrentProfile);
  const handleAppendProfileContent = (content) => {
    setInputValue((prevValue) => prevValue + " " + content);
  };

  /*const LinkRenderer = ({ href, children }) => (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );*/

  const [isFavorite, setIsFavorite] = useState(
    user.favorites?.generators?.includes(currentTool?._id)
  );

  useEffect(() => {
    setIsFavorite(user.favorites?.generators?.includes(currentTool?._id));
  }, [user, currentTool]);

  const toggleFavorite = async () => {
    let updatedFavorites;
    const newFavoriteStatus = !isFavorite;
    setIsFavorite(newFavoriteStatus);
    try {
      await axios.post(`${apiURL}/favorites/update`, {
        dynamic_id: user.dynamic_id,
        item_id: currentTool._id,
        type: "generators",
        isFavorite: newFavoriteStatus,
      });
      dispatch(
        addToast(
          newFavoriteStatus ? "Favorite Added!" : "Favorite Removed!",
          "success"
        )
      );

      if (newFavoriteStatus) {
        updatedFavorites = {
          ...user.favorites,
          ["generators"]: [...user.favorites["generators"], currentTool._id],
        };
      } else {
        updatedFavorites = {
          ...user.favorites,
          ["generators"]: user.favorites["generators"].filter(
            (id) => id !== currentTool._id
          ),
        };
      }
      const updatedUser = {
        ...user,
        favorites: updatedFavorites,
      };
      dispatch(setUser(updatedUser));
    } catch (error) {
      setIsFavorite(!newFavoriteStatus);
      dispatch(addToast("Favorite NOT updated! Please try again.", "error"));
    }
  };

  const currentThreadId = useSelector(selectCurrentThreadId);
  const threadHistory = useSelector((state) => state.threadHistory.threads); // Access thread history from state
  const threadMessages = useSelector((state) => state.threadHistory.currentThreadMessages); // Access current thread messages from state
  const [messages, setMessages] = useState([]);
  const [messagesToSubmit, setMessagesToSubmit] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [submittedMessages, setSubmittedMessages] = useState([]);
  const [awaitingResponse, setAwaitingResponse] = useState(false);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [result, setResult] = useState("");
  const messagesEndRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [dropdownLabel, setDropdownLabel] = useState("Make it...");
  const [isTalking, setIsTalking] = useState(false);
  const [showOption, setShowOption] = useState("profiles");
  const [imageUrl, setImageUrl] = useState('');
  const [fileUrl, setFileUrl] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  if (!currentTool && tools.length > 0) {
    const specificTool = tools.find(
      (tool) => tool._id === config.defaultToolId
    );
    if (specificTool) {
      dispatch(setCurrentTool(specificTool));
    }
  }

  useEffect(() => {
    if (currentTool && currentTool?.name) {
      setMessages([
        {
          id: 1,
          text: currentTool?.instructions
            ? currentTool?.instructions
            : `Hey! I'm ${currentTool?.name}. What's up?`,
          isUser: false,
        },
      ]);
    }
  }, [currentTool]);

  const md = markdownit({
    html: true,
    linkify: true,
    typographer: true,
    breaks: true,
    highlight: function (str, lang) {
      if (lang && hljs.getLanguage(lang)) {
        try {
          return `
          <div class='relative border border-gray-600 rounded' style='padding: 0 18px; margin: 0; background-color: #272822'>
            <button class="copy-button cursor-pointer absolute top-5 right-5 border border-primary px-4 py-2 rounded hover:text-gray-500 hover:border-gray-500">Copy Code</button>
            <pre><code class="hljs" style="margin: 0 8px; padding: 0;">${hljs.highlight(str, { language: lang, ignoreIllegals: true }).value}</code></pre>
            <button class="copy-button cursor-pointer absolute bottom-5 right-5 border border-primary px-4 py-2 rounded hover:text-gray-500 hover:border-gray-500">Copy Code</button>
          </div>
        `;
      } catch (__) {}
    }
    return `
      <div class='relative border border-gray-600 rounded' style='padding: 0 18px; margin: 0; background-color: #272822'>
        <button class="copy-button cursor-pointer absolute top-5 right-5 border border-primary px-4 py-2 rounded hover:text-gray-500 hover:border-gray-500">Copy Code</button>
        <pre><code class="hljs" style="margin: 0 8px; padding: 0;">${md.utils.escapeHtml(str)}</code></pre>
        <button class="copy-button cursor-pointer absolute bottom-5 right-5 border border-primary px-4 py-2 rounded hover:text-gray-500 hover:border-gray-500">Copy Code</button>
      </div>
    `;
    },
  })
    .use(markdownItMermaid)
    .use(taskLists, { enabled: true })
    .use(mdVideos, {
      youtube: { width: 640, height: 390 },
      vimeo: { width: 500, height: 281 },
      vine: { width: 600, height: 600, embed: 'simple' },
      prezi: { width: 550, height: 400 },
    })
    .use(emoji);
  
  function MarkdownComponent({ markdownText }) {
    useEffect(() => {
      const copyButtons = document.querySelectorAll('.copy-button');
      copyButtons.forEach((button) => {
        button.addEventListener('click', (e) => {
          const code = button.nextElementSibling.textContent;
          navigator.clipboard.writeText(code).then(() => {
            alert('Copied to clipboard!');
          }).catch((err) => {
            console.error('Failed to copy: ', err);
          });
        });
      });
    }, [markdownText]);
    const renderedMarkdown = md.render(markdownText);
  
    return <div dangerouslySetInnerHTML={{ __html: renderedMarkdown }} />;
  }

  const handleCopy = async (text) => {
    const isCopied = await copyToClipboard(text);
    if (isCopied) {
      dispatch(addToast("Copied to clipboard!", "success"));
    } else {
      dispatch(addToast("Failed to copy!", "error"));
    }
  };

  const handleSend = async (text = inputValue) => {
    if (!session) {
      dispatch(addToast("Please login to chat!", "error"));
      setIsAnalyzing(false);
      return;
    }
    if (user.ai_credits < 1) {
      dispatch(addToast("You need more AI credits to chat!", "error"));
      setIsAnalyzing(false);
      return;
    }
    if (currentTool.type === "assistant" && inputValue.trim() !== "") {
      setMessages((messages) => [
        ...messages,
        { id: messages.length + 1, text: inputValue, isUser: true },
      ]);
      setInputValue("");
      setIsAnalyzing(true);
      setAwaitingResponse(true);
      const postAssistant = await postMessage(
        user.dynamic_id,
        inputValue,
        currentThreadId,
        currentTool.oai_assistantId
      );
      setResult("");
      //console.log('postAssistant:', postAssistant);
      if (postAssistant) {
        setMessages((messages) => [
          ...messages,
          {
            id: messages.length + 1,
            text: postAssistant.data,
            isUser: false,
            service: "openai",
            messageCount: 1,
          },
        ]);
        if (postAssistant?.tokens) {
          dispatch(reduceAICredits(postAssistant.tokens));
        }
        setAwaitingResponse(false);
        //dispatch(setThreadId(postAssistant.threadId));
        if (!currentThreadId) {
          dispatch(setThreadId(postAssistant.threadId));
        }
        scrollToBottom();
        setIsAnalyzing(false);
        //setDropdownLabel('Make it...');
        //setAwaitingResponse(true);
      }
    } else {
      /**/
      if (inputValue?.trim() !== "") {
        const newMessage = { role: "user", content: inputValue };
        //console.log('inputValue in handleSend:', inputValue);
        setMessages((messages) => [
          ...messages,
          { id: messages.length + 1, text: inputValue, isUser: true },
        ]);
        setMessagesToSubmit((messagesToSubmit) => [
          ...messagesToSubmit,
          newMessage,
        ]);
        setInputValue("");
        setAwaitingResponse(true);
        setTimeout(() => {
          setIsTalking(true);
        }, 500);
      } else {
        dispatch(addToast("Please enter a message!", "error"));
        setIsAnalyzing(false);
      }
    }
  };

  const handleRegenerate = (messageId, isUser) => {
    if (isUser) {
      // If the icon clicked is from a user's message, simply resend that message
      const messageToRegenerate = messages.find(
        (message) => message.id === messageId
      );
      handleSend(messageToRegenerate.text);
    } else {
      // If the icon clicked is from an assistant's response, find the preceding user's message to regenerate
      for (let i = messageId - 1; i >= 0; i--) {
        const previousMessage = messages[i];
        if (previousMessage.isUser) {
          handleSend(previousMessage.text);
          break;
        }
      }
    }
  };

  useEffect(() => {
    const checkScroll = () => {
      if (messagesEndRef.current) {
        const isAtBottom =
          messagesEndRef.current.scrollHeight -
            messagesEndRef.current.scrollTop ===
          messagesEndRef.current.clientHeight;
        setShowScrollButton(!isAtBottom);
      }
    };

    if (messagesEndRef.current) {
      messagesEndRef.current.addEventListener("scroll", checkScroll);
    }

    return () => {
      if (messagesEndRef.current) {
        messagesEndRef.current.removeEventListener("scroll", checkScroll);
      }
    };
  }, [messagesEndRef.current]);

  const scrollToBottom = () => {
    messagesEndRef.current.scrollTo({
      top: messagesEndRef.current.scrollHeight,
      behavior: "smooth",
    });
  };

  /*useEffect(() => {
  if (currentPrompt) {
    const fetchChatResponse = async () => {
      const newMessage = { role: 'user', content: currentPrompt };
      setMessages(messages => [...messages, { id: messages.length + 1, text: currentPrompt, isUser: true }]);
      setMessagesToSubmit(messagesToSubmit => [...messagesToSubmit, newMessage]);
      //setInputValue('');
      dispatch(setCurrentPrompt(null));
      setAwaitingResponse(true);
      if (location.pathname !== '/') {
        navigate('/');
      }
    };

    fetchChatResponse();
  }
}, [currentPrompt, dispatch]);*/

  const handleNewChat = () => {
    setMessages([
      {
        id: 1,
        text: currentTool?.instructions
          ? currentTool?.instructions
          : `Hey! I'm ${currentTool?.name}. What's up?`,
        isUser: false,
      },
    ]);
    setMessagesToSubmit([]);
    setSubmittedMessages([]);
    setAwaitingResponse(false);
    setResult("");
    dispatch(setThreadId(null));
    dispatch(setCurrentThreadMessages([]));
  };

  /*useEffect(() => {
  console.log('Current Thread ID:', currentThreadId)
  if (!currentThreadId) {
    handleNewChat();
  }
}, [currentThreadId]);*/

useEffect(() => {
  if (currentThreadId) {
    const thread = threadHistory.find(thread => thread._id === currentThreadId);
    if (thread) {
      dispatch(setCurrentThreadMessages(thread.messages));
    }
  }
}, [currentThreadId, threadHistory, dispatch]);

useEffect(() => {
  if (threadMessages?.length > 0) {
    const formattedMessages = threadMessages.map(msg => ({
      id: msg._id, // or a unique identifier
      text: msg.content, // map content to text
      isUser: msg.role === 'user' ? true : false // assuming you have this flag in your message object
    }));
    setMessages(formattedMessages);
  }
}, [threadMessages]);

  useEffect(() => {
    const messageListener = (message) => {
      if (
        message.toolId &&
        message.dynamic_id &&
        message.dynamic_id === user.dynamic_id
      ) {
        let accumulator;

        if (message.chunkContent) {
          setIsAnalyzing(false);
          accumulator += message.chunkContent;
          setResult((prevResult) => prevResult + message.chunkContent);
          scrollToBottom();

          if (message === "--complete--") {
            accumulator = "";
          }
        }
      }
    };

    socket.on("chatStream", messageListener);

    return () => {
      socket.off("chatStream", messageListener);
    };
  }, [socket, user, currentTool]);

  useEffect(() => {
    if (
      messagesToSubmit.length > submittedMessages.length &&
      awaitingResponse
    ) {
      const callGenerateChat = async () => {
        try {
          const lastNewMessage = messagesToSubmit[messagesToSubmit.length - 1];
          const chatResponse = await generateChat(
            messagesToSubmit,
            lastNewMessage.content,
            "Best",
            currentTool._id,
            user.dynamic_id
          );
          //console.log("Chat Response:", chatResponse);

          setMessages((messages) => [
            ...messages,
            {
              id: messages.length + 1,
              text: chatResponse.data,
              isUser: false,
            },
          ]);
          setMessagesToSubmit((messagesToSubmit) => [
            ...messagesToSubmit,
            { role: "assistant", content: chatResponse.data },
          ]);
          setResult("");
          dispatch(setUser(chatResponse?.user));
          setSubmittedMessages((submittedMessages) => [
            ...submittedMessages,
            lastNewMessage,
          ]);
          setIsTalking(false);
          setAwaitingResponse(false);
        } catch (error) {
          console.error("Failed to generate chat:", error);
          setAwaitingResponse(false);
          setIsAnalyzing(false);
          dispatch(addToast("Failed to generate chat!", "error"));
        }
      };

      callGenerateChat();
    }
  }, [messagesToSubmit, currentTool]);

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      if (e.shiftKey || e.ctrlKey) {
        e.preventDefault();
        // Insert a new line character
        const { selectionStart, selectionEnd } = e.target;
        const newValue =
          inputValue.substring(0, selectionStart) +
          "\n" +
          inputValue.substring(selectionEnd);
        setInputValue(newValue);
        // Move the cursor to the correct position after the newline
        setTimeout(() => {
          e.target.selectionStart = e.target.selectionEnd = selectionStart + 1;
        }, 0);
      } else {
        e.preventDefault();
        handleSend();
      }
    }
  };

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [inputValue]);

  const handleSendMessage = () => {
    handleSend();
  };

  const dropdownOptions = [
    "a checklist",
    "shorter",
    "longer",
    "more difficult",
    "less difficult",
  ];

  const handleDropdownSelect = (option) => {
    setIsDropdownOpen(false); // Close dropdown
    setDropdownLabel(option); // Update button label
    handleSend(`Regenerate your previous message to be ${option}.`);
    //console.log(`API call for option: ${option}`);
  };

  const handleOpenModal = (jsonData) => {
    const apiEndpoint = `${config.apiUrl}/blogposts/${config.app_id}`;

    try {
      const parsedJsonData = JSON.parse(jsonData);
      dispatch(
        openModal({ jsonData: parsedJsonData, apiEndpoint, type: "Blog Post" })
      );
    } catch (error) {
      console.error("Invalid JSON string:", error);
      addToast("Invalid JSON string!", "error");
    }
  };

  return (
    <>
      
      {/* Desktop */}
      <div
        className="hidden lg:flex flex-col rounded chat-return h-95vh">
        <div className="flex flex-col md:flex-row">
          <div className="w-full h-full hidden md:w-1/2 md:min-w-1/2 md:flex lg:w-1/4 lg:min-w-1/4 justify-center items-center p-3 pt-0 pb-0 h-80vh xl:h-85vh">
            <div className="flex flex-col gap-2 h-full justify-start mt-5 items-center">
              <div className="flex flex-row gap-2 justify-center items-center">
                <img
                  src={currentTool?.image_data.example_url}
                  alt="Avatar"
                  className={`hidden md:block border-2 border-primary rounded-full w-20 h-auto ${
                    isTalking ? "talking" : ""
                  }`}
                />
                <div className="text-center mb-0 md:mb-3 text-2xl font-bold text-poetsen text-primary">
                  {currentTool?.name}
                </div>
              </div>
              <div className="w-full p-3 rounded border border-primary max-h-52 text-lg overflow-auto slim-scrollbar">
                <div className="text-sm max-h-20 overflow-auto slim-scrollbar">
                  {currentTool?.description}
                </div>
              </div>
              <div className="flex flex-row gap-2 flex-wrap justify-between">
                <button
                  onClick={toggleFavorite}
                  className="bg-primary px-4 py-2 rounded hover:bg-gray-500"
                >
                  <FontAwesomeIcon
                    icon={isFavorite ? solidStar : regularStar}
                    className="text-yellow-400"
                  />
                </button>
                <button
                  onClick={() => setShowOption("profiles")}
                  className="bg-primary px-4 py-2 rounded hover:bg-gray-500"
                >
                  <FontAwesomeIcon icon={faAddressBook} />
                </button>
                <button
                  onClick={() => setShowOption("upload")}
                  className="bg-primary px-4 py-2 rounded hover:bg-gray-500"
                >
                  <FontAwesomeIcon icon={faUpload} />
                </button>
              </div>
              {showOption && <div className="flex flex-row w-full hidden xl:block p-4 border border-primary mt-2 md:mt-0 overflow-auto rounded">
                {showOption === "profiles" && (
                  <SidebarProfilesCard
                    onProfileClick={handleAppendProfileContent}
                    header={'Click a profile to add it to your conversation.'}
                  />
                )}
                {showOption === "upload" && (
                  <div>
                    <h2 className="text-center text-poetsen text-primary">Upload an Image/File</h2>
                    <FileUpload
                      uploadUrl={`${config.apiUrl}/admin/upload`}
                      onSuccess={(url) => setImageUrl(url)}
                    />
                    {imageUrl && (<div className="flex flex-row justify-between w-full gap-4">
                      <img src={imageUrl} alt="Uploaded" className="w-12 h-auto" />
                      <button className='flex flex-row text-center p-2 bg-body border border-primary text-primary rounded w-full justify-center items-center' onClick={() => copyToClipboard(imageUrl)}>
                        <FontAwesomeIcon icon={faCopy} className="mr-2" />Copy URL</button>
                      </div>)}
                  </div>
                )}
              </div>}
            </div>
          </div>

          {/* Right column for displaying messages */}
          <div
            className="w-full md:w-1/2 lg:w-3/4 md:max-w-1/2 lg:max-w-3/4 mt-3 md:mt-0 p-4 pb-20 flex flex-col bg-primary overflow-auto h-80vh xl:h-85vh"
            ref={messagesEndRef}
          >
            {messages.map((message, index) => (
              <div
                key={message.id}
                className={`flex flex-col items-center p-2 rounded-lg shadow mb-2 ${
                  message.isUser
                    ? "bg-primary border ml-56"
                    : "text-primary bg-body"
                }`}
              >
                <div className="flex flex-col w-11/12">
                  {/*<ReactMarkdown
                    className="markdown-content"
                    remarkPlugins={[remarkGfm]}
                    rehypePlugins={[rehypeRaw]}
                    components={{
                      a: LinkRenderer,
                    }}
                  >
                    {message.text}
                  </ReactMarkdown>*/}
                  <MarkdownComponent markdownText={message.text} />
                  {message.isUser && index > 0 && (
                    <div className="flex flex-row items-center w-full justify-end gap-1 mt-1">
                      <button
                        className="bg-primary hover:bg-gray-700 text-white px-3 py-1 rounded"
                        onClick={() => handleCopy(message.text)}
                      >
                        <FontAwesomeIcon icon={faCopy} />
                      </button>
                      <BoardSelectorButton
                        itemContent={message.text}
                        itemType={"prompt"}
                        userId={user._id}
                      />
                    </div>
                  )}

                  {!message.isUser && index > 0 && (
                    <div className="flex flex-row items-center w-full justify-end gap-1 mt-1">
                      <button
                        className="bg-primary hover:bg-gray-700 text-white px-3 py-1 rounded"
                        onClick={() => handleCopy(message.text)}
                      >
                        <FontAwesomeIcon icon={faCopy} />
                      </button>
                      <BoardSelectorButton
                        itemContent={message.text}
                        itemType={"text"}
                        userId={user._id}
                      />
                      {(currentTool?._id === "664e91fb0e38b974f62d3cea" || currentTool?._id === "665f952b72ea17168666b981") &&
                        !message.isUser &&
                        index > 0 &&
                        message.text.includes('{') && message.text.includes('}') && (
                          <div className="flex flex-col items-end">
                            <button
                              onClick={() => handleOpenModal(message.text)}
                              className="bg-primary px-4 py-1 rounded hover:bg-gray-700 text-white "
                            >
                              <FontAwesomeIcon icon={faEdit} className="mr-2" />
                              Edit/Add
                            </button>
                          </div>
                        )}
                    </div>
                  )}
                  
                </div>
              </div>
            ))}
            {isAnalyzing && (
              <div
                className={`flex flex-col justify-center items-center p-2 rounded-lg shadow mb-2 text-primary bg-body shimmer`}
              >
                Analyzing
              </div>
            )}
            {result && (
              <div
                className={`flex flex-col items-center p-2 rounded-lg shadow mb-2 text-primary bg-body`}
              >
                <div className="flex flex-col w-11/12">
                  <MarkdownComponent markdownText={result} />
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="bg-inverted pt-4 md:p-4 absolute bottom-2 w-full xl:w-4/5">
          <div className="flex gap-2 items-end w-full xl:w-4/5 mx-auto">
            <textarea
              ref={textareaRef}
              value={inputValue}
              onChange={handleChange}
              onKeyPress={handleKeyPress}
              placeholder="Type a message..."
              rows={1}
              className="flex-grow p-2 rounded border border-gray-500 text-black bg-gray-100 resize-none overflow-hidden"
              style={{ maxHeight: "250px" }} // Adjust the max height as needed
            />
            <button
              onClick={handleSend}
              className="bg-primary font-bold p-2 rounded hover:bg-gray-500"
            >
              <FontAwesomeIcon icon={faPaperPlane} className="mr-2" />
              SEND IT
            </button>
            <button className="bg-primary font-bold p-2 rounded hover:bg-gray-500" onClick={handleNewChat}>
              <FontAwesomeIcon icon={faArrowRotateRight} className="mr-2" />
              New Chat
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatComponent;
