import React from 'react';
import { Link } from 'react-router-dom';
import JobManager from '../components/magicflows/JobManager';

function MagicFlowsPage() {
  return (<>
  <div className="flex flex-col mx-auto min-h-96 w-11/12">
    <JobManager />
  </div>
  </>);
}

export default MagicFlowsPage;