import { createSlice } from '@reduxjs/toolkit';

const userNFTsSlice = createSlice({
  name: 'userNFTs',
  initialState: {
    nfts: [],
    status: 'idle', // 'idle', 'loading', 'succeeded', 'failed'
    error: null,
  },
  reducers: {
    // Action to set the NFTs array
    setNFTs(state, action) {
      state.nfts = action.payload;
    },
    // Optional: Add more actions here if needed
  },
});

// Action creators are generated for each case reducer function
export const { setNFTs } = userNFTsSlice.actions;

export default userNFTsSlice.reducer;

export const selectNFTs = state => state.userNFTs.nfts;