import axios from 'axios';
import config from '../../config/config';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const apiURL = config.apiUrl;

export const fetchBanners = createAsyncThunk('banners/fetchBanners', async () => {
  const response = await axios.get(`${apiURL}/fetch/Banner`);
  const banners = response.data.data;

  const filteredBanners = banners.filter(banner => {
      const currentDate = new Date();
      const startDate = banner.start_date ? new Date(banner.start_date) : null;
      const endDate = banner.end_date ? new Date(banner.end_date) : null;

      // Check if the current date is after the start date (if start date exists)
      const isValidStartDate = startDate ? currentDate >= startDate : true;

      // Check if the current date is before the end date (if end date exists)
      const isValidEndDate = endDate ? currentDate <= endDate : true;

      return isValidStartDate && isValidEndDate;
  });

  return filteredBanners;
});

// Async thunk for fetching tool data
/*export const fetchBanners = createAsyncThunk('banners/fetchBanners', async () => {
    const response = await axios.get(`${apiURL}/fetch/Banner`);
    console.log('banners response.data: ', response.data.data);
    return response.data.data;
  });*/

  const bannersSlice = createSlice({
    name: 'banners',
    initialState: {
      banners: [],
      status: 'idle', // 'idle', 'loading', 'succeeded', 'failed'
      error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchBanners.pending, (state, action) => {
          state.status = 'loading';
        })
        .addCase(fetchBanners.fulfilled, (state, action) => {
          state.status = 'succeeded';
          // Add any fetched banners to the array
          state.banners = action.payload;
        })
        .addCase(fetchBanners.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.error.message;
        });
    },
  });
  
  export default bannersSlice.reducer;