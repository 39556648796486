import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectCurrentTool } from '../features/currentTool/currentToolSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { copyToClipboard } from '../utils/commons';
import { addToast } from '../features/ui/uiSlice';
import { setCurrentPrompt } from '../features/chat/chatSlice';

const SuggestedPrompts = () => {
    const [isOpen, setIsOpen] = useState(false);
    const dispatch = useDispatch();
    const currentTool = useSelector(selectCurrentTool);
    const toggleDropdown = () => setIsOpen(!isOpen);

    const handleCopy = async (text) => {
        const isCopied = await copyToClipboard(text);
        if (isCopied) {
            dispatch(addToast('Copied to clipboard!', 'success'));
        } else {
            dispatch(addToast('Failed to copy!', 'error'));
        }
    }

    const handleSubmit = (prompt) => {
      dispatch(setCurrentPrompt(prompt));
    };
  
    return (
      <div className="w-full">
        <button onClick={toggleDropdown} className="flex justify-between items-center w-full px-4 py-2 bg-primary text-inverted font-semibold mt-2">
          <span>Suggested Prompts</span>
          <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} />
        </button>
        {isOpen && (
          <div className="w-full bg-white border rounded shadow max-h-80 overflow-auto">
            {currentTool?.suggested_prompts?.map((prompt, index) => (
              <div key={index} className="px-4 py-2 border-bflex items-center bg-gray-200 text-gray-700 hover:bg-gray-700 hover:text-gray-200 p-1 pl-2 border border-gray-700">
                <FontAwesomeIcon icon={faArrowRight} className='mr-2' />{prompt}
                <button onClick={() => handleSubmit(prompt)} className="cursor-pointer ml-2 text-s rounded border bg-blue-500 text-white pl-2 pr-2">Use</button>
                <button onClick={() => handleCopy(prompt)} className="cursor-pointer ml-2 text-s rounded border bg-blue-500 text-white pl-2 pr-2">Copy</button>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };
  
  export default SuggestedPrompts;
  