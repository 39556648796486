import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRecords, addRecord, deleteRecord } from '../features/tools/recordsSlice';
import Table from '../components/Table';
import Pagination from '../components/Pagination';
import Dropdown from '../components/Dropdown';
import SearchBar from '../components/SearchBar';
//import Button from '../components/Button';
import ModalUploadable from '../components/ModalUploadable.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowUp, faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { addToast } from '../features/ui/uiSlice';

const UploadablesPage = () => {
  const dispatch = useDispatch();
  const uploadables = useSelector((state) => [
    ...(state.tools.items.uploadables ?? [])
  ].sort((a, b) => a.name.localeCompare(b.name)));
  const records = useSelector((state) => state.records.items);
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [selectedUploadable, setSelectedUploadable] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [searchQuery, setSearchQuery] = useState('');
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [newRecord, setNewRecord] = useState({});

  useEffect(() => {
    if (selectedUploadable) {
      dispatch(fetchRecords({ model: selectedUploadable.model, page, pageSize }));
    }
  }, [selectedUploadable, page, pageSize, dispatch, newRecord]);

  useEffect(() => {
    const sortedRecords = records
      .filter(record =>
        Object.values(record).some(value =>
          value.toString().toLowerCase().includes(searchQuery.toLowerCase())
        )
      )
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)); // Sorting records by createdAt in descending order
    
    setFilteredRecords(sortedRecords);
  }, [records, searchQuery]);

  const handleUploadableChange = (uploadable) => {
    setSelectedUploadable(uploadable);
    setPage(1);
    setSearchQuery('');
  };

  const handleDelete = (id) => {
    if (window.confirm('Are you sure you want to delete this record?')) {
      dispatch(deleteRecord({ model: selectedUploadable.model, id }));
      dispatch(addToast('Record deleted successfully!', 'success'));
    }
  };

  const handleAddRecord = () => {
    dispatch(addRecord({ model: selectedUploadable.model, recordData: newRecord }));
    dispatch(addToast('Record added successfully!', 'success'));
    setIsAddModalOpen(false);
    setNewRecord({});
  };

  const handlePageSizeChange = (size) => {
    setPageSize(size);
    setPage(1);
  };

  return (
    <div className="container mx-auto p-4 pt-0 min-h-96">
      <div className='flex justify-between items-end mb-2 gap-10'>
        <h1 className="text-2xl font-bold mb-2 text-primary text-poetsen"><FontAwesomeIcon icon={faCloudArrowUp} className='mr-2' />Uploadables</h1>
        {selectedUploadable ? ( // Displaying the selected uploadable name
          <h2 className="text-2xl border border-primary rounded px-4 py-2 font-bold mb-2 text-primary text-poetsen">{selectedUploadable.name}</h2>
        ): (
          <h2 className="text-2xl border border-primary rounded px-4 py-2 font-bold mb-2 text-poetsen">Select the Uploadable you want to manage.</h2>
        )}
      </div>
      <div className='flex justify-between items-center mb-4 gap-10'>
        <Dropdown
          options={uploadables.map(u => ({ label: u.name, value: u }))}
          onChange={handleUploadableChange}
        />
        {selectedUploadable && (
          <>
            <SearchBar value={searchQuery} onChange={setSearchQuery} />
            <button className="flex flex-row justify-center items-center text-center bg-primary px-4 py-2 rounded hover:bg-gray-700 hover:text-white" onClick={() => setIsAddModalOpen(true)}><FontAwesomeIcon icon={faPlusCircle} className='mr-2' />Add</button>

          </>
        )}
        
      </div>
      {selectedUploadable && (
        <>
          {/*<div className="flex justify-between my-4">
            <SearchBar value={searchQuery} onChange={setSearchQuery} />
            <button className="flex flex-row justify-center items-center text-center bg-primary px-4 py-2 rounded hover:bg-gray-700 hover:text-white" onClick={() => setIsAddModalOpen(true)}><FontAwesomeIcon icon={faPlusCircle} className='mr-2' />Add</button>
      </div>*/}
          <Table
            data={filteredRecords.slice((page - 1) * pageSize, page * pageSize)}
            columns={selectedUploadable.fields.map(field => ({ label: field.label, accessor: field.label }))}
            onDelete={handleDelete}
            actions={[
              { label: 'Delete', icon: <FontAwesomeIcon icon={faTrash} />, onClick: handleDelete },
            ]}
          />
          <Pagination
            page={page}
            pageSize={pageSize}
            onPageChange={setPage}
            onPageSizeChange={handlePageSizeChange}
          />
        </>
      )}
      {isAddModalOpen && (
        <ModalUploadable onClose={() => setIsAddModalOpen(false)}>
          <h2 className='text-poetsen text-primary'><FontAwesomeIcon icon={faPlusCircle} className='mr-2' />Add New Record</h2>
          <form onSubmit={handleAddRecord}>
            {selectedUploadable.fields.map(field => (
              <div key={field.label} className='flex flex-col gap-1 my-3'>
                <label>{field.label}</label>
                {field.input === 'text' && (
                  <input
                    type={field.input}
                    value={newRecord[field.label] || ''}
                    onChange={e => setNewRecord({ ...newRecord, [field.label]: e.target.value })}
                    required={field.required}
                    className='text-black p-2 border border-primary rounded'
                  />
                )}
                {field.input === 'textarea' && (
                  <textarea
                    type={field.input}
                    value={newRecord[field.label] || ''}
                    onChange={e => setNewRecord({ ...newRecord, [field.label]: e.target.value })}
                    required={field.required}
                    className='text-black p-2 border border-primary rounded'
                  />
                )}
                {field.input === 'select' && (
                  <select
                    value={newRecord[field.label] || ''}
                    onChange={e => setNewRecord({ ...newRecord, [field.label]: e.target.value })}
                    required={field.required}
                    className='text-black p-2 border border-primary rounded'
                  >
                    <option value=''>Select an option</option>
                    {field.options.map(option => (
                      <option key={option} value={option}>{option}</option>
                    ))}
                  </select>
                )}
                
              </div>
            ))}
            <button type="submit" className='mt-3 w-full inline-flex justify-center rounded shadow-sm px-4 py-2 bg-primary text-base font-medium hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm justify-center items-center'><FontAwesomeIcon icon={faPlusCircle} className='mr-2' />Add Record</button>
          </form>
        </ModalUploadable>
      )}
    </div>
  );
};

export default UploadablesPage;