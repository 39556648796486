import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import config from '../config/config';
import { useNavigate, useLocation } from 'react-router-dom';
//import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { selectUser, setUser, reduceAICredits } from '../features/user/userSlice';
import { selectSession } from '../features/session/sessionSlice';
import { setThreadId, selectCurrentThreadId, updateThreadId, deleteThreadId } from '../features/threads/threadSlice';
import { selectCurrentTool, setCurrentTool } from '../features/currentTool/currentToolSlice';
import { selectCurrentNFTChat, setCurrentNFTChat } from '../features/currentTool/currentNFTChatSlice';
import { generateChat, postMessage } from '../api/aiFunctions';
//import { selectCurrentPrompt, setResponse, setLoading, setError, setCurrentPrompt } from '../features/chat/chatSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowRotateRight, faCopy, faSave, faArrowRight, faChevronDown, faWandMagicSparkles, faPaperPlane, faDiceD20, faSquarePlus } from '@fortawesome/free-solid-svg-icons';
import ReactMarkdown from 'react-markdown';
//import remarkGfm from 'remark-gfm';
import socket from '../utils/socket';
import { copyToClipboard } from '../utils/commons';
import { addToast } from '../features/ui/uiSlice';
//import SavePrompt from './SavePrompt';
//import ModalPromptSave from './ModalPromptSave';
//import mermaid from "mermaid";
import markdownit from 'markdown-it'
import hljs from "highlight.js";
import markdownItMermaid from "markdown-it-mermaid";
import taskLists from "markdown-it-task-lists";
import mdVideos from "markdown-it-video";
//import miliMarkdown from "markdown-it-linkify-images";
import { full as emoji } from 'markdown-it-emoji'
import { useUser } from '@thirdweb-dev/react';
import { useActiveWallet } from "thirdweb/react";
import { useIsMobileOrTablet, useIsLaptopOrDesktop } from '../utils/useScreenSize';
import { Link } from 'react-router-dom';
import { faStar as solidStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as regularStar } from '@fortawesome/free-regular-svg-icons';
import { current } from '@reduxjs/toolkit';


const NFTChatComponent = () => {
  
  const dispatch = useDispatch();
  const apiURL = config.apiUrl;
  const tools = useSelector((state) => [
    ...state.tools.items.tools, // Spread the existing tools array
    ...state.tools.items.assistants // Spread the assistants array
  ]);
  
  
  const user = useSelector(selectUser);
  const session = useSelector(selectSession);
  const currentTool = useSelector(selectCurrentTool);
  const currentNFTChat = useSelector(selectCurrentNFTChat);
  /*const [isFavorite, setIsFavorite] = useState(
    user.favorites?.generators?.includes(currentTool._id)
  );

  useEffect(() => {
      setIsFavorite(user.favorites?.generators?.includes(currentTool._id));
  }, [user, currentTool]);

  const toggleFavorite = async () => {
    let updatedFavorites;
    const newFavoriteStatus = !isFavorite;
    setIsFavorite(newFavoriteStatus);
    try {
        await axios.post(`${apiURL}/favorites/update`, {
            dynamic_id: user.dynamic_id,
            item_id: currentTool._id,
            type: 'generators',
            isFavorite: newFavoriteStatus,
        });
        dispatch(addToast(newFavoriteStatus ? 'Favorite Added!' : 'Favorite Removed!', 'success'));

        if (newFavoriteStatus) {
            updatedFavorites = {
                ...user.favorites,
                ['generators']: [...user.favorites['generators'], currentTool._id],
            };

        } else {
            updatedFavorites = {
                ...user.favorites,
                ['generators']: user.favorites['generators'].filter((id) => id !== currentTool._id),
            };
        }
        const updatedUser = {
            ...user,
            favorites: updatedFavorites,
        };
        dispatch(setUser(updatedUser));
      } catch (error) {
        setIsFavorite(!newFavoriteStatus);
        dispatch(addToast('Favorite NOT updated! Please try again.', 'error'));
      }
  };*/

    const isLaptopOrDesktop = useIsLaptopOrDesktop();
  
  const currentThreadId = useSelector(selectCurrentThreadId);
  const [messages, setMessages] = useState([]);
  const [messagesToSubmit, setMessagesToSubmit] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [submittedMessages, setSubmittedMessages] = useState([]);
  const [awaitingResponse, setAwaitingResponse] = useState(false);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [result, setResult] = useState('');
  const [result2, setResult2] = useState('');
  const messagesEndRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [dropdownLabel, setDropdownLabel] = useState('Make it...');
  const [isTalking, setIsTalking] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  
  if (!currentTool && tools.length > 0) {
    dispatch(setCurrentTool(tools[0]));
  }

  useEffect(() => {
    if (!currentNFTChat) {
      setMessages([{ id: 1, text: `Select an NFT to start chatting!`, isUser: false}]);
    } else if (currentTool && currentTool?.name) {
      setMessages([{ id: 1, text: currentTool?.instructions ? currentTool?.instructions : `Hey! I'm ${currentTool?.name}. What's up?`, isUser: false}]);
    }
  }, [currentTool, currentNFTChat]);

  const md = markdownit({
    html: true,
    linkify: true,
    typographer: true,
    breaks: true,
    highlight: function (str, lang) {
      if (lang && hljs.getLanguage(lang)) {
        try {
          return '<pre><code class="hljs">' +
                 hljs.highlight(str, { language: lang, ignoreIllegals: true }).value +
                 '</code></pre>';
        } catch (__) {}
      }
  
      return '<pre><code class="hljs">' + md.utils.escapeHtml(str) + '</code></pre>';
    }
  }).use(markdownItMermaid).use(taskLists, { enabled: true }).use(mdVideos, {
    youtube: { width: 640, height: 390 },
    vimeo: { width: 500, height: 281 },
    vine: { width: 600, height: 600, embed: 'simple' },
    prezi: { width: 550, height: 400 }}
  ).use(emoji);

  function MarkdownComponent({ markdownText }) {
    const renderedMarkdown = md.render(markdownText);
  
    return (
      <div dangerouslySetInnerHTML={{ __html: renderedMarkdown }} />
    );
  }
  

  const handleCopy = async (text) => {
    const isCopied = await copyToClipboard(text);
    if (isCopied) {
        dispatch(addToast('Copied to clipboard!', 'success'));
    } else {
        dispatch(addToast('Failed to copy!', 'error'));
    }
  }

const handleSend = async (text = inputValue) => {
  if (!session) {
    dispatch(addToast('Please login to chat!', 'error'));
    return;
  }
  if (user.ai_credits < 1) {
    dispatch(addToast('You need more AI credits to chat!', 'error'));
    return;
  }
  if (currentTool.type === 'assistant' && inputValue.trim() !== '') {
    setMessages(messages => [...messages, { id: messages.length + 1, text: inputValue, isUser: true }]);
    setResult(`The Game Master is typing...`);
    setAwaitingResponse(true);
    const postAssistant = await postMessage(user.dynamic_id, inputValue, currentThreadId, currentTool.oai_assistantId);
    setResult('');
    console.log('postAssistant:', postAssistant);
    if (postAssistant) {
      setMessages(messages => [
          ...messages,
          {
              id: messages.length + 1,
              text: postAssistant.data,
              isUser: false,
              service: 'openai',
              messageCount: 1,
          }
      ]);
      if (postAssistant?.tokens) {
        dispatch(reduceAICredits(postAssistant.tokens));
      }
      setAwaitingResponse(false);
      dispatch(setThreadId(postAssistant.threadId));
      setInputValue('');
      scrollToBottom();
      //setDropdownLabel('Make it...'); 
      //setAwaitingResponse(true);
    }
  } else {/**/
    if (inputValue?.trim() !== '') {
      const newMessage = { role: 'user', content: inputValue };
      console.log('inputValue in handleSend:', inputValue);
      setMessages(messages => [...messages, { id: messages.length + 1, text: inputValue, isUser: true }]);
      setMessagesToSubmit(messagesToSubmit => [...messagesToSubmit, newMessage]);
      setInputValue('');
      setAwaitingResponse(true);
      setTimeout(() => {
        setIsTalking(true);
      }, 500);
    } else {
      dispatch(addToast('Please enter a message!', 'error'));
    }
  }
};

const handleRegenerate = (messageId, isUser) => {
  if (isUser) {
    // If the icon clicked is from a user's message, simply resend that message
    const messageToRegenerate = messages.find(message => message.id === messageId);
    handleSend(messageToRegenerate.text);
  } else {
    // If the icon clicked is from an assistant's response, find the preceding user's message to regenerate
    for (let i = messageId - 1; i >= 0; i--) {
      const previousMessage = messages[i];
      if (previousMessage.isUser) {
        handleSend(previousMessage.text);
        break;
      }
    }
  }
};

useEffect(() => {
  const checkScroll = () => {
    if (messagesEndRef.current) { 
      const isAtBottom = messagesEndRef.current.scrollHeight - messagesEndRef.current.scrollTop === messagesEndRef.current.clientHeight;
      setShowScrollButton(!isAtBottom);
    }
  };

  if (messagesEndRef.current) {
    messagesEndRef.current.addEventListener('scroll', checkScroll);
  }

  return () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.removeEventListener('scroll', checkScroll);
    }
  };
}, [messagesEndRef.current]); 

const scrollToBottom = () => {
  messagesEndRef.current.scrollTo({
    top: messagesEndRef.current.scrollHeight,
    behavior: 'smooth',
  });
};



/*useEffect(() => {
  if (currentPrompt) {
    const fetchChatResponse = async () => {
      const newMessage = { role: 'user', content: currentPrompt };
      setMessages(messages => [...messages, { id: messages.length + 1, text: currentPrompt, isUser: true }]);
      setMessagesToSubmit(messagesToSubmit => [...messagesToSubmit, newMessage]);
      //setInputValue('');
      dispatch(setCurrentPrompt(null));
      setAwaitingResponse(true);
      if (location.pathname !== '/workspace') {
        navigate('/workspace');
      }
    };

    fetchChatResponse();
  }
}, [currentPrompt, dispatch]);*/

const handleNewChat = () => {
  setMessages([]);
  setMessagesToSubmit([]);
  setSubmittedMessages([]);
  setAwaitingResponse(false);
  setResult('');
  //dispatch(setThreadId(null));
};

/*useEffect(() => {
  console.log('Current Thread ID:', currentThreadId)
  if (!currentThreadId) {
    handleNewChat();
  }
}, [currentThreadId]);*/

useEffect(() => {
  const messageListener = (message) => {
    console.log('Message:', message, 'currentToolID: ', currentTool?._id, 'User :', user);
    if (message.toolId && message.dynamic_id && message.dynamic_id === user.dynamic_id) {
      let accumulator;

      if (message.chunkContent) {
        accumulator += message.chunkContent;
        console.log('Chunk Content:', message.chunkContent);
        setResult(prevResult => prevResult + message.chunkContent);
        scrollToBottom();

        if (message === '--complete--') {
          accumulator = '';
        }
      }
    }
  };

  socket.on("chatStream", messageListener);

  return () => {
    socket.off("chatStream", messageListener);
  };
}, [socket, user, currentTool]);

useEffect(() => {  
  if (messagesToSubmit.length > submittedMessages.length && awaitingResponse) {
    const callGenerateChat = async () => {
      try {
          const lastNewMessage = messagesToSubmit[messagesToSubmit.length - 1];
          const chatResponse = await generateChat(
            messagesToSubmit,
            lastNewMessage.content,
            'Best',
            currentTool._id,
            user.dynamic_id,
            currentNFTChat
          );
          console.log('Chat Response:', chatResponse);

          setMessages(messages => [
            ...messages,
            {
                id: messages.length + 1,
                text: chatResponse.data,
                isUser: false,
                //service: responseItem.serviceName,
                //messageCount: messageCount,
                // If it's the second message, assign it to text2 and service2 instead
                //...(index === 1 && { text2: responseItem.fullContent, service2: responseItem.serviceName }),
            }
        ]);
        setMessagesToSubmit(messagesToSubmit => [
            ...messagesToSubmit, 
            { role: 'assistant', content: chatResponse.data }
        ]);
      

        //setMessages(messages => [...messages, { id: messages.length + 1, text: chatResponse.data, isUser: false }]);
        //setMessagesToSubmit(messagesToSubmit => [...messagesToSubmit, { role: 'assistant', content: chatResponse.data }]);
        setResult('');
        dispatch(setUser(chatResponse?.user));
          setSubmittedMessages(submittedMessages => [...submittedMessages, lastNewMessage]);
          setIsTalking(false);
          setAwaitingResponse(false);
      } catch (error) {
        console.error('Failed to generate chat:', error);
        setAwaitingResponse(false);
      }
    };

    callGenerateChat();
  }
}, [messagesToSubmit, currentTool]);

const handleChange = (e) => {
  setInputValue(e.target.value);
};

const handleKeyPress = (e) => {
  if (e.key === 'Enter') {
    handleSend();
  }
};

const handleSendMessage = () => {
  handleSend();
};

const dropdownOptions = ["a checklist", "shorter", "longer", "more difficult", "less difficult"];

const handleDropdownSelect = (option) => {
  setIsDropdownOpen(false); // Close dropdown
  setDropdownLabel(option); // Update button label
  handleSend(`Regenerate your previous message to be ${option}.`);
  console.log(`API call for option: ${option}`);
};

const styleHeight = isLaptopOrDesktop ? '84vh' : '70vh';

  return (<>
    {/* Mobile version */}
    <div className="flex flex-col lg:hidden rounded h-screen pb-40">
      <div className="flex flex-col h-full overflow-auto">
        <div className="flex-grow flex flex-col justify-end max-h-max h-100" ref={messagesEndRef}>
          {messages.map((message) => (
            <div key={message.id} className={`flex flex-col justify-center items-center p-2 rounded-lg shadow mb-2 ${message.isUser ? 'bg-primary border ml-8' : 'text-primary bg-body border border-primary'}`}>
              {!message.isUser && (
                <img
                  src={currentNFTChat ? currentNFTChat?.image.thumbnailUrl : currentTool?.image_data.example_url}
                  alt="Avatar"
                  className="w-10 h-10 mr-4 rounded-full"
                />
              )}
              <div className='flex flex-col w-full'>
                <ReactMarkdown>{message.text}</ReactMarkdown>
              </div>
            </div>
          ))}
          {result && (
            <div className={`flex flex-col justify-center items-center p-2 rounded-lg shadow mb-2 text-primary bg-body border border-primary`}>
              <img
                src={currentNFTChat ? currentNFTChat?.image.thumbnailUrl : currentTool?.image_data.example_url}
                alt="Avatar"
                className="w-10 h-10 mr-4 rounded-full"
              />
              <div className='flex flex-col w-full'>
                {currentTool.type === 'assistant' && awaitingResponse ? (
                  <div className='flex flex-row items-center'>
                    <FontAwesomeIcon icon={faDiceD20} className='text-primary mr-2 spinner' />
                    The Game Master is typing...
                  </div>
                ) : (
                  <ReactMarkdown>{result}</ReactMarkdown>
                )}
              </div>
            </div>
          )}
        </div>

        <div className="sticky bottom-0 bg-inverted pt-4 md:p-4 w-full">
          <div className="flex gap-2">
            <input
              type="text"
              value={inputValue}
              onChange={handleChange}
              onKeyPress={handleKeyPress}
              placeholder="Type a message..."
              className="flex-grow p-2 rounded border border-gray-500 text-black bg-gray-100"
            />
            
            {!currentNFTChat ? (<>
              <button onClick={()=>navigate('/nftchat')} className="text-poetsen bg-primary font-bold p-2 rounded hover:text-white hover:bg-gray-700">
              <FontAwesomeIcon icon={faSquarePlus} className='mr-2' />
              Choose NFT
              </button>
            </>) : (<>
              <button onClick={handleSend} className="text-poetsen bg-primary font-bold p-2 rounded hover:text-white hover:bg-gray-700">
              <FontAwesomeIcon icon={faPaperPlane} className='mr-2' />
              SEND IT
              </button>
            </>)}
          </div>
        </div>
      </div>
    </div>

    {/* Desktop */}
    <div className="hidden lg:flex flex-col md:w-full rounded border border-primary" style={{height: '84vh', minHeight: '400px'}}>
      <div className="flex flex-col w-full md:flex-row md:flex-grow">
        <div className="w-full hidden md:w-1/2 md:flex justify-center items-center p-3 pt-0 pb-0" style={{height: '76vh', minHeight: '400px'}}>
          <div className='flex flex-row md:flex-col gap-2 h-full justify-center items-center'>
            <img
              src={currentNFTChat ? currentNFTChat?.image.thumbnailUrl : currentTool?.image_data.example_url}
              alt="Avatar"
              className={`hidden md:block border-2 border-primary rounded-full w-24 md:w-32 xl:w-44 h-auto ${isTalking ? 'talking' : ''}`}
            />
            <div className='w-full p-5 rounded mt-4 h-48 md:h-96 text-lg overflow-auto slim-scrollbar'>
             
              <div className='text-center mb-0 md:mb-3 text-xl md:text-2xl lg:text-3xl font-bold text-poetsen text-primary'>
                {currentTool?.name}
              </div>
              <div className='w-full p-3 rounded border border-primary max-h-52 text-lg overflow-auto slim-scrollbar'>
                {currentNFTChat?.name || currentNFTChat?.contract?.name ? (<>
                  <div className='text-sm text-center'>
                    <span className='text-poetsen font-bold text-base text-primary'>{currentNFTChat?.name || currentNFTChat?.contract?.name} (#{currentNFTChat?.tokenId})</span><br />
                    {currentNFTChat?.description || currentNFTChat?.contract?.description}
                  </div>
                  <div className='flex flex-row justify-center items-center mt-4'>
                    <button onClick={()=>dispatch(setCurrentNFTChat(null))} className="text-poetsen bg-primary font-bold py-2 px-10 rounded hover:bg-gray-700 hover:text-white">
                      <FontAwesomeIcon icon={faArrowRotateRight} className='mr-2' />
                      Clear NFT
                    </button>
                  </div>
                </>) : (<>
                  <div className='text-sm'>
                    {currentTool?.description}
                  </div>
                  <div className='flex flex-col gap-3 justify-center items-center mt-4'>
                    <button onClick={()=>navigate('/nftchat')} className="text-poetsen bg-primary font-bold py-2 px-10 rounded hover:bg-gray-700 hover:text-white">
                      <FontAwesomeIcon icon={faSquarePlus} className='mr-2' />
                      Choose NFT
                    </button>
                    <button onClick={()=>navigate('/browsenfts')} className="text-poetsen bg-primary font-bold py-2 px-10 rounded hover:bg-gray-700 hover:text-white">
                      <FontAwesomeIcon icon={faSquarePlus} className='mr-2' />
                      Browse Popular NFTs
                    </button>
                  </div>
                </>)}
              </div>
            </div>
          </div>
        </div>

        {/* Right column for displaying messages */}
        <div className="w-full md:w-1/2 mt-3 md:mt-0 p-4 flex flex-col bg-primary overflow-auto" style={{maxHeight: '76vh'}} ref={messagesEndRef}>
          {messages.map((message) => (
            <div key={message.id} className={`flex flex-row items-center p-2 rounded-lg shadow mb-2 ${message.isUser ? 'bg-primary border ml-8' : 'text-primary bg-body'}`}>
              {!message.isUser && (
                <img
                  src={currentNFTChat ? currentNFTChat?.image.thumbnailUrl : currentTool?.image_data.example_url}
                  alt="Avatar"
                  className="w-10 h-10 mr-4 rounded-full"
                /> 
              )}
              <div className='flex flex-col w-full'>
                <ReactMarkdown>{message.text}</ReactMarkdown>
                
              </div>
            </div>
          ))}
          {result && (
            <div className={`flex flex-row items-center p-2 rounded-lg shadow mb-2 text-primary bg-body`}>
              
                <img
                  src={currentNFTChat ? currentNFTChat?.image.thumbnailUrl : currentTool?.image_data.example_url}
                  alt="Avatar"
                  className="w-10 h-10 mr-4 rounded-full"
                /> 
              <div className='flex flex-col w-full'>
                
                {currentTool.type === 'assistant' ? (
                  <div className='flex flex-row items-center'>
                  <FontAwesomeIcon icon={faDiceD20} className='text-primary mr-2 spinner' />
                  The Game Master is typing...
                </div>
                ) : (
                  <ReactMarkdown>{result}</ReactMarkdown>
                )}
                
              </div>
            </div>
            )}
        </div>
      </div>

      {/* Bottom chat input area */}
      <div className="bg-inverted pt-4 md:p-4">
        <div className="flex gap-2">
          <input
            type="text"
            value={inputValue}
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            placeholder="Type a message..."
            className="flex-grow p-2 rounded border border-gray-500 text-black bg-gray-100"
          />
          
            {!currentNFTChat ? (<>
              <button onClick={()=>navigate('/nftchat')} className="text-poetsen bg-primary font-bold p-2 rounded hover:text-white hover:bg-gray-700">
              <FontAwesomeIcon icon={faSquarePlus} className='mr-2' />
              Choose NFT
              </button>
            </>) : (<>
              <button onClick={handleSend} className="text-poetsen bg-primary font-bold p-2 rounded hover:text-white hover:bg-gray-700">
              <FontAwesomeIcon icon={faPaperPlane} className='mr-2' />
              SEND IT
              </button>
            </>)}
        </div>
      </div>
    </div>

  </>);
};

export default NFTChatComponent;
