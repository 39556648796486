import React from 'react';

const Dropdown = ({ options, onChange }) => {
  const handleChange = (event) => {
    if (event.target.value === "") {
      // When the default option is selected, call onChange with null or undefined
      onChange(null);
    } else {
      // Find the option that matches the selection
      const selectedOption = options.find(option => option.value.name === event.target.value);
      if (selectedOption) {
        onChange(selectedOption.value);
      }
    }
  };

  return (
    <div className="relative inline-block w-full text-gray-900">
      <select
        className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
        onChange={handleChange}
        defaultValue=""
      >
        <option value="">Select an uploadable...</option>
        {options.map((option) => (
          <option key={option.value.name} value={option.value.name}>
            {option.label}
          </option>
        ))}
      </select>
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
          <path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"/>
        </svg>
      </div>
    </div>
  );
};

export default Dropdown;