import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { openNewsletterModal } from '../features/games/modalNewsletterSlice';

const FunBanner = ({size}) => {
  const dispatch = useDispatch();
  const adsRaw = useSelector((state) => state.banners.banners);
  const ads = adsRaw.filter(ad => ad.size === size);
  const [currentAdIndex, setCurrentAdIndex] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentAdIndex((currentAdIndex + 1) % ads.length);
    }, 5000); // Rotate ads every 5 seconds

    return () => clearTimeout(timer); // Clear the timer when the component unmounts
  }, [currentAdIndex, ads.length]);

  const handleAdClick = (event, ad) => {
    // Prevent navigation if the URL is null
    if (!ad.url) {
      event.preventDefault();
    }
    
    if (ad.action === 'openNewsletterModal') {
      dispatch(openNewsletterModal());
    }
  };

  return (
    <>
      <div className="flex w-full lg:w-1/3 h-auto mx-auto justify-center flex-row justify-between items-center mb-3 text-white rounded-lg">
        {ads[currentAdIndex] && (
          <Link to={ads[currentAdIndex].url || '#'} target={ads[currentAdIndex].target || '_self'} onClick={(e) => handleAdClick(e, ads[currentAdIndex])} className='mx-auto'>
            <div className='flex flex-col items-center justify-center'>
                <img src={ads[currentAdIndex].src} alt={ads[currentAdIndex].alt} className='rounded' />
            </div>
          </Link>
        )}
      </div>
    </>
  );
};

export default FunBanner;
