import { createSlice } from '@reduxjs/toolkit';

const loadStateFromLocalStorage = () => {
    try {
      const serializedState = localStorage.getItem('servicesData');
      if (serializedState === null) {
        return {
          openai: false,
          stablediffusion: false,
          leonardo: false,
          perplexity: false,
        };
      }
      return JSON.parse(serializedState);
    } catch (err) {
      return {
        openai: false,
        stablediffusion: false,
        leonardo: false,
        perplexity: false,
      };
    }
  };
  
  const initialState = {
    data: loadStateFromLocalStorage(),
    status: 'idle',
    error: null,
  };

export const servicesSlice = createSlice({
  name: 'services',
  initialState,
  reducers: {
    // Action to toggle the status of a service
    toggleServiceStatus: (state, action) => {
      const { service } = action.payload;
      if (state.data.hasOwnProperty(service)) {
        state.data[service] = !state.data[service];
      }
    },
    // Actions to handle the loading state and errors can be added here
    setLoading: (state) => {
      state.status = 'loading';
    },
    setSucceeded: (state) => {
      state.status = 'succeeded';
    },
    setFailed: (state, action) => {
      state.status = 'failed';
      state.error = action.payload; // Assuming payload is an error message
    },
    // Reset the error
    resetError: (state) => {
      state.error = null;
    },
  },
});

// Export actions
export const { toggleServiceStatus, setLoading, setSucceeded, setFailed, resetError } = servicesSlice.actions;

// Export the reducer
export default servicesSlice.reducer;
