import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentTool, selectCurrentTool } from '../features/currentTool/currentToolSlice';
import { close } from '../features/mobile/menuSlice';
import { setThreadId } from '../features/threads/threadSlice';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight, faHome, faComments, faImages, faUserNinja, faSignOutAlt, faTachometerAlt, faArrowRight, faTools, faStore, faDollarSign, faDiceD20, faRobot, faGear, faHouse, faPuzzlePiece, faFire, faImagePortrait, faWandMagicSparkles, faPaperclip, faCloudArrowUp, faWaveSquare } from '@fortawesome/free-solid-svg-icons';
import { openModalIFrame } from '../features/modals/modaliframeSlice';
import { openModal } from '../features/packages/modalPackagesSlice';
import { openToolsModal } from '../features/tools/toolsModalSlice';
import { fullLogout } from '../api/userFunctions';
import { selectSession, deleteSession } from '../features/session/sessionSlice';
import { logout } from '../features/user/userSlice';
import ConnectButtonMobileComponent from './ConnectButtonMobile';

const SidebarHome = () => {
  const [openSection, setOpenSection] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const session = useSelector(selectSession);
  const user = useSelector(state => state.user.user);
  const tools = useSelector((state) => [
    ...(state.tools.items.tools ?? []), // Use optional chaining or default to empty array
    ...(state.tools.items.assistants ?? [])
  ].sort((a, b) => a.name.localeCompare(b.name)));
  
  const currentTool = useSelector(selectCurrentTool);
  const loading = useSelector((state) => state.tools.status) === 'loading';

  const toggleSection = (section) => {
    setOpenSection(openSection === section ? null : section);
  };

  const handleLogout = async () => {
    const loggingOut = await fullLogout(session);
    dispatch(logout());
    dispatch(deleteSession());
    navigate('/login');
};

  // Mapping tool types to section names for easier lookup
  const toolTypeToSection = {
    assistant: "Assistants",
    chatbot: "Chatbots",
    image: "Image Generators",
    "character-famous": "Characters",
    tool: "Tools",
    lab: "Tools",
  };

  const sectionIcons = {
    Dashboard: faTachometerAlt,
    "Assistants": faWandMagicSparkles,
    "Chatbots": faComments,
    "Image Generators": faImages,
    Characters: faUserNinja,
    "Tools": faTools,
  };  

  const getSectionName = (toolType) => {
    if (toolType === 'chatbot' || toolType === 'chatbot-app') {
      return 'Chatbots';
    }
    return toolTypeToSection[toolType] || 'Unknown Section';
  };

  const sectionOrder = [
    "Dashboard", // Assuming you want to keep Dashboard at the very top
    "Assistants",
    "Tools",
    "Chatbots",
    "Image Generators",
    "Characters",
    
    // Add other sections if necessary
  ];
  
  // Before rendering sections, sort them based on the sectionOrder
  const sortedSections = [...new Set(tools.map(tool => getSectionName(tool.type)))]
    .sort((a, b) => sectionOrder.indexOf(a) - sectionOrder.indexOf(b));



  return (
    <div className="text-poetsen w-80 lg:w-64 flex flex-col overflow-y-auto slim-scrollbar h-95vh">
      <div className="flex items-center justify-center space-x-4 w-full">
            <img
                  src={user?.pic}
                  alt="Avatar"
                  className="w-16 h-16 rounded-full border-2 border-primary"
                /> 
            <div className='flex flex-col justify-start leading-none'>
                {/*<div className='text-2xl text-primary font-bold text-poetsen'>Andy's Stuff</div>*/}
                <div className='text-lg'>AI Credits: {Math.round(user?.ai_credits)}</div>
                <button onClick={()=> {navigate('/dashboard'); dispatch(close());}} className="mt-1 flex items-center hover:text-primary hover:bg-gray-700 hover:text-gray-200 px-3 py-2 border border-primary rounded">
                  <FontAwesomeIcon icon={faTachometerAlt} className='text-primary' />
                  <span className="ml-2">Dashboard</span>
              </button>
            </div>
        </div>
        <div className='w-full flex flex-col justify-center mt-3'>
          <ConnectButtonMobileComponent />
        </div>

        {/*!userAddress && <div className="shake border rounded bg-primary border-gray-100 mt-4 p-3 text-sm text-black">
          <span className='font-bold text-2xl leading-none text-inverted'>How to get started at Andy's Place!?</span>
          <ul className='list-disc list-inside mt-2'>
            <li><span className='font-bold'>Connect Wallet:</span> Access Andy's exclusive tools and games. Start with 5K FREE Credits. Buy credits at any time.</li>
            <li><span className='font-bold'>No Wallet? No Worries!</span> Still explore the Merch Shop, Shill Shack and More!</li>
          </ul>
  </div>

        <div className='w-full hidden lg:flex flex-col justify-center mt-3'>
          <ConnectButtonComponent />
        </div>
        <div className='w-full flex lg:hidden flex-col justify-center mt-3'>
          <ConnectButtonMobileComponent />
        </div>*/}

      {/* Navigation Items */}
      <div className="flex-1 mt-2 lg:mt-5">
        
        {sortedSections.map((section) => (
          <div key={section}>
            <button
              className="w-full text-left flex items-center px-1 py-3 hover:bg-gray-700 hover:text-gray-200 focus:outline-none border border-primary rounded mb-2"
              onClick={() => toggleSection(section)}
            >
              <FontAwesomeIcon icon={sectionIcons[section]} className="mr-2 ml-2 text-primary" />
              {section}
              <FontAwesomeIcon icon={openSection === section ? faChevronDown : faChevronRight} className="ml-auto mr-1 text-primary" />
            </button>
            {openSection === section && (
              <div className="py-2 space-y-2 max-h-96 overflow-auto mb-2 slim-scrollbar">
                {!loading &&
                  tools
                    .filter((tool) => getSectionName(tool.type) === section)
                    .map((tool) => (
                        <Link 
                        key={tool._id} 
                        to={`/`} 
                        onClick={() => {
                            
                            if (currentTool._id !== tool._id) {
                                dispatch(setCurrentTool(tool));
                            dispatch(setThreadId(null));
                            }
                            dispatch(close());
                        }} 
                        className="flex items-center bg-primary hover:bg-gray-700 hover:text-gray-200 p-1 pl-2 border border-gray-700 rounded"
                        >                        <FontAwesomeIcon icon={faArrowRight} className='mr-2' />
                        <img src={tool.image_data.example_url} alt={tool.name} className="h-10 w-10 inline rounded-full mr-2" />
                        {tool.name}
                      </Link>
                    ))}
              </div>
            )}
          </div>
        ))}
        <Link to={`/`} className="w-full justify-center flex items-center px-1 py-3 hover:bg-gray-700 hover:text-gray-200 focus:outline-none border border-primary rounded mb-2" onClick={() => {dispatch(openToolsModal()); dispatch(close());}}>
            View All Tools
        </Link>
        
      </div>

      <div className="border-t border-primary mt-5 pt-3">
            {/*<button className='mb-2 block w-full text-left px-5 py-3 bg-primary hover:text-gray-200 hover:bg-gray-700 focus:outline-none rounded' onClick={()=>{navigate('/packages'); dispatch(close());}}>         
                <FontAwesomeIcon icon={faRobot} className='mr-2' />Buy AI Credits
            </button>
            <Link to='/nftchat' onClick={() => {dispatch(close())}} className="mt-2 block w-full text-left px-5 py-3 bg-primary hover:text-gray-200 hover:bg-gray-700 focus:outline-none rounded">
                <FontAwesomeIcon icon={faImages} className='mr-2' />My NFTs
            </Link>*/}
            <Link to='/boards' onClick={() => {dispatch(close())}} className="mt-2 block w-full text-left px-5 py-3 bg-primary hover:text-gray-200 hover:bg-gray-700 focus:outline-none rounded">
                <FontAwesomeIcon icon={faPaperclip} className='mr-2' />Boards
            </Link>
            <Link to='/uploadables' onClick={() => {dispatch(close())}} className="mt-2 block w-full text-left px-5 py-3 bg-primary hover:text-gray-200 hover:bg-gray-700 focus:outline-none rounded">
                <FontAwesomeIcon icon={faCloudArrowUp} className='mr-2' />Uploadables
            </Link>
            <Link to='/magicflows' onClick={() => {dispatch(close())}} className="mt-2 block w-full text-left px-5 py-3 bg-primary hover:text-gray-200 hover:bg-gray-700 focus:outline-none rounded font-bold">
                <FontAwesomeIcon icon={faWaveSquare} className='mr-2' />Magic Flows
            </Link>
            
            {/*<button className='mt-2 mb-2 block w-full text-left px-5 py-3 bg-primary text-inverted hover:text-gray-200 hover:bg-gray-700 focus:outline-none rounded font-bold' onClick={()=>{dispatch(openModalIFrame({ url:'https://www.sushi.com/swap?chainId=8453&token0=NATIVE&token1=0x18A8BD1fe17A1BB9FFB39eCD83E9489cfD17a022&swapAmount=' })); dispatch(close());}}>         
                <FontAwesomeIcon icon={faDollarSign} className='mr-2' />Buy Andy on SushiSwap
      </button>*/}
            
            
        </div>

      {/* User Profile */}
      {user && (
        <div className="px-5">
          {/*<img src={user.pic} alt="User" className="h-12 w-12 rounded-full mx-auto border-2 border-gray-600" />
          <h2 className="mt-2 text-center">{user.name}</h2>*/}
          <div className="mt-4 text-center">
            <Link to="/settings" onClick={() => {dispatch(close())}} className="inline-block w-full text-primary px-4 py-1 border border-primary rounded hover:bg-gray-700 focus:outline-none focus:ring">
              <FontAwesomeIcon icon={faGear} className='mr-2' />Settings
            </Link>
            <button onClick={() => {handleLogout(); dispatch(close());}} className="mt-2 flex items-center w-full text-primary border border-primary rounded hover:bg-gray-700 justify-center p-1">
                    <FontAwesomeIcon icon={faSignOutAlt} className='mr-2' />Logout
                </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SidebarHome;
