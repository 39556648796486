const config = {
    siteTitle: "GAIM Admin",
    siteName: "GAIM Admin",
    siteDescription: "GAIM Admin",
    siteKeywords: "NFTs, chat with NFT, non-fungible token, cryptocurrency, blockchain, AI chat, nft merch, nft art community, digital currency, crypto investment, AI characters, blockchain technology",
    path: "gaimadmin",
    logoUrl: "https://gaim.nyc3.cdn.digitaloceanspaces.com/logo512.png",
    favicon: "https://gaim.nyc3.cdn.digitaloceanspaces.com/gaimLogo300x300jpg.jpg",
    shopUrl: "https://shop.andys.place",
    app_id: "6647e961cc5bbe61ddc4c735",
    app_url: "https://admin.gaim.ai",
    twitter: "@gaimnetwork",
    mailerLiteGroupID: '121609948156134441',
    defaultToolId: '664a99c4cc5bbe61ddc4c80e',
    
    /*apiUrl: "http://localhost:8080",
    stytch_login_url: "http://localhost:3000/loggingin",
    stytch_public_token: "public-token-test-87fd74cf-f520-4946-a3cd-32f67dde94aa",
    flowsApiUrl: 'http://localhost:5000',*/

    stytch_login_url: "https://admin.gaim.ai/loggingin",
    apiUrl: "https://andy-back-end-loi4p.ondigitalocean.app",
    stytch_public_token: "public-token-live-b7c04a5d-12b5-4e2a-a4ad-c93e4d0a1953",
    flowsApiUrl: 'https://andy-back-end-loi4p.ondigitalocean.app',
  };
  
  export default config;