import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getJobs, addJob, deleteJob, updateJob } from '../../api/magicFlowsFunctions';
import Table from '../Table';
import { faArrowCircleUp, faClock, faDeleteLeft, faPlusCircle, faTrash, faWaveSquare, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { addToast } from '../../features/ui/uiSlice';

const JobManager = () => {
    const dispatch = useDispatch();
    const tools = useSelector((state) => (state.tools));
    const [jobs, setJobs] = useState([]);
    const [showAdd, setShowAdd] = useState(false);

    useEffect(() => {
        fetchJobs();
    }, []);

    const fetchJobs = async () => {
        try {
            const jobs = await getJobs();
            setJobs(jobs);
        } catch (error) {
            console.error('Failed to fetch jobs:', error);
        }
    };

    const handleAddJob = async () => {
      console.log('newRecord', newRecord);
        try {
            if (!newRecord.action) {
                dispatch(addToast('Please select a flow!', 'error'));
                return;
            }
            await addJob(newRecord);
            fetchJobs();
            setNewRecord({});
        } catch (error) {
            console.error('Failed to add job:', error);
        }
    };

    const handleDelete = async (jobId) => {
        if (window.confirm('Are you sure you want to delete this record?')) {
            await deleteJob(jobId);
            fetchJobs();
            dispatch(addToast('Record deleted successfully!', 'success'));
        }
    };

    const handleCancelAdd = () => {
        setShowAdd(false);
        setNewRecord({});
    };

    const handleClearForm = () => {
        setNewRecord({});
    };

    const handleToggleShowAdd = () => {
        setShowAdd(!showAdd);
    };

    const cronSchedules = [
        { value: '* * * * *', label: 'Every minute' },
        { value: '*/5 * * * *', label: 'Every 5 minutes' },
        { value: '*/10 * * * *', label: 'Every 10 minutes' },
        { value: '*/15 * * * *', label: 'Every 15 minutes' },
        { value: '*/30 * * * *', label: 'Every 30 minutes' },
        { value: '0 * * * *', label: 'Every hour' },
        { value: '0 */2 * * *', label: 'Every 2 hours' },
        { value: '0 */4 * * *', label: 'Every 4 hours' },
        { value: '0 */6 * * *', label: 'Every 6 hours' },
        { value: '0 */12 * * *', label: 'Every 12 hours' },
        { value: '0 0 * * *', label: 'Every day at midnight' },
        { value: '0 6 * * *', label: 'Every day at 6:00 AM' },
        { value: '0 9 * * *', label: 'Every day at 9:00 AM' },
        { value: '0 12 * * *', label: 'Every day at noon' },
        { value: '0 15 * * *', label: 'Every day at 3:00 PM' },
        { value: '0 18 * * *', label: 'Every day at 6:00 PM' },
        { value: '0 21 * * *', label: 'Every day at 9:00 PM' },
        { value: '0 9 * * 0', label: 'Every Sunday at 9:00 AM' },
        { value: '0 9 * * 1', label: 'Every Monday at 9:00 AM' },
        { value: '0 9 * * 2', label: 'Every Tuesday at 9:00 AM' },
        { value: '0 9 * * 3', label: 'Every Wednesday at 9:00 AM' },
        { value: '0 9 * * 4', label: 'Every Thursday at 9:00 AM' },
        { value: '0 9 * * 5', label: 'Every Friday at 9:00 AM' },
        { value: '0 9 * * 6', label: 'Every Saturday at 9:00 AM' },
        { value: '0 0 * * 0', label: 'Every Sunday at Midnight' },
        { value: '0 0 * * 1', label: 'Every Monday at Midnight' },
        { value: '0 9 1 * *', label: 'On the 1st of every month at 9:00 AM' },
        { value: '0 9 15 * *', label: 'On the 15th of every month at 9:00 AM' },
    ];

    const columns = [
        { label: 'Action', accessor: 'action' },
        { label: 'User Message', accessor: 'user_message' },
        { label: 'Collection', accessor: 'collection' },
        { label: 'App ID', accessor: 'app_id' },
        { label: 'OpenAI Assistant ID', accessor: 'oai_assistantId' },
        { label: 'Author', accessor: 'author' },
        { label: 'Schedule', accessor: 'schedule' },
    ];

    const variableOptionsMap = {
        tools: tools.items.tools.map(tool => ({ value: tool._id, label: tool.name })),
        assistants: tools.items.assistants.map(assistant => ({ value: assistant._id, label: assistant.name })),
        profiles: tools.items.profiles.map(profile => ({ value: profile._id, label: profile.name })),
        uploadables: tools.items.uploadables.map(uploadable => ({ value: uploadable._id, label: uploadable.name })),
        mlGroupIds: tools.items.mlGroupIds.map(mlGroupId => ({ value: mlGroupId._id, label: mlGroupId.name })),
        authors: tools.items.authors.map(author => ({ value: author._id, label: author.name })),
        magicflows: tools.items.magicflows.map(magicflow => ({ value: magicflow._id, label: magicflow.name })),
        users: tools.items.users.map(user => ({ value: user._id, label: user.name })),
        appIds: tools.items.appIds.map(appId => ({ value: appId._id, label: appId.name })),
    };

    const magicflows = useSelector((state) => [
        ...state.tools.items.magicflows,
    ].sort((a, b) => a.name.localeCompare(b.name)));

    const [formMetadata, setFormMetadata] = useState(magicflows[0]);
    const [newRecord, setNewRecord] = useState({});

    useEffect(() => {
        if (formMetadata.fields) {
            const initialFormState = formMetadata.fields.reduce((acc, field) => {
                if (field.input === 'hidden') {
                    acc[field.key] = field.value;
                }
                return acc;
            }, {});
            setNewRecord({ ...initialFormState, action: formMetadata.action });
        }
    }, [formMetadata]);

    const handleChange = (e, key) => {
        setNewRecord({ ...newRecord, [key]: e.target.value });
    };

    const handleMultiSelectChange = (e, key) => {
        const options = Array.from(e.target.selectedOptions, option => option.value);
        setNewRecord({ ...newRecord, [key]: options });
    };

    const handleMagicflowChange = (e) => {
        const selectedMagicflow = magicflows.find(magicflow => magicflow._id === e.target.value);
        setFormMetadata(selectedMagicflow);
        setNewRecord({ ...newRecord, action: selectedMagicflow.action });
    };

    return (
        <div className='w-full'>
            <div className='border-b border-primary pb-1 mb-4'>
                <h1 className='text-3xl text-poetsen'>
                    <FontAwesomeIcon icon={faWaveSquare} className='mr-2 text-primary' />
                    Magic Flows
                </h1>
                <p className='text-primary'>Create and manage automated tasks</p>
            </div>
            <div className='w-full lg:w-2/3 xl:w-1/2 mt-6 border-b border-primary p-4 mb-4 rounded bg-primary flex flex-col gap-4 justify-center items-start'>
                <div className='flex flex-row justify-between w-full gap-4 items-center cursor-pointer' onClick={handleToggleShowAdd}>
                    <div className='text-xl text-poetsen cursor-pointer hover:text-green-400'>
                        <FontAwesomeIcon icon={faPlusCircle} className='mr-2' />
                        Add New Flow
                    </div>
                    {showAdd && (<FontAwesomeIcon icon={faArrowCircleUp} className='text-2xl cursor-pointer hover:text-gray-900' />)}
                </div>
                {showAdd && (<>
                    <div className='flex flex-col gap-1 w-full'>
                        <label className="block font-bold capitalize text-sm">Select Magic Flow</label>
                        <select
                            value={formMetadata._id}
                            onChange={handleMagicflowChange}
                            required
                            className='w-full text-black px-3 py-2 border border-gray-300 rounded'
                        >
                            <option disabled value=''>Select a Magic Flow</option>
                            {magicflows.map(magicflow => (
                                <option name={magicflow.name} key={magicflow._id} value={magicflow._id}>{magicflow.name}</option>
                            ))}
                        </select>
                    </div>

                    {formMetadata.fields && formMetadata.fields.map(field => (
                        <div key={field.key} className='flex flex-col gap-1 w-full'>
                            <label className="block font-bold capitalize text-sm">{field.label}{field.input === 'multiselect' ? ' (Use ctrl or shift to select multiple records)' : ''}</label>
                            {field.input === 'text' && (
                                <input
                                    type='text'
                                    value={newRecord[field.key] || ''}
                                    onChange={e => handleChange(e, field.key)}
                                    required={field.required}
                                    className='w-full text-black px-3 py-2 border border-gray-300 rounded'
                                />
                            )}
                            {field.input === 'textarea' && (
                                <textarea
                                    value={newRecord[field.key] || ''}
                                    onChange={e => handleChange(e, field.key)}
                                    required={field.required}
                                    className='w-full text-black px-3 py-2 border border-gray-300 rounded'
                                />
                            )}
                            {field.input === 'select' && (
                                <select
                                    value={newRecord[field.key] || ''}
                                    onChange={e => handleChange(e, field.key)}
                                    required={field.required}
                                    className='text-black p-2 border border-primary rounded'
                                >
                                    <option value=''>Select an option</option>
                                    {field.option_type === 'static' &&
                                        field.options_static.map(option => (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    {field.option_type === 'variable' &&
                                        variableOptionsMap[field.option_variable].map(option => (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                </select>
                            )}
                            {field.input === 'multiselect' && (
                                <select
                                    multiple
                                    value={newRecord[field.key] || []}
                                    onChange={e => handleMultiSelectChange(e, field.key)}
                                    required={field.required}
                                    className='text-black p-2 border border-primary rounded'
                                >
                                    {field.option_type === 'static' &&
                                        field.options_static.map(option => (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    {field.option_type === 'variable' &&
                                        variableOptionsMap[field.option_variable].map(option => (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                </select>
                            )}
                            {field.input === 'hidden' && (
                                <input
                                    type='hidden'
                                    value={field.value}
                                    readOnly
                                />
                            )}
                        </div>
                    ))}
                    
                    <div className='flex flex-col gap-1 w-full'>
                        <label className="block font-bold capitalize text-sm">Run Schedule</label>
                        <select
                            name="schedule"
                            value={newRecord.schedule || ''}
                            onChange={(e) => setNewRecord({ ...newRecord, schedule: e.target.value })}
                            className="w-full text-black px-3 py-2 border border-gray-300 rounded"
                        >
                            {cronSchedules.map((schedule) => (
                                <option key={schedule.value} value={schedule.value}>
                                    {schedule.label}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className='w-full flex justify-end gap-4 items-center'>
                        <button onClick={handleClearForm} className="px-4 py-2 bg-body text-white rounded hover:bg-red-400 text-poetsen">
                            <FontAwesomeIcon icon={faDeleteLeft} className='mr-2' />Clear Form
                        </button>
                        <button onClick={handleCancelAdd} className="px-4 py-2 bg-body text-white rounded hover:bg-red-400 text-poetsen">
                            <FontAwesomeIcon icon={faXmarkCircle} className='mr-2' />Cancel
                        </button>
                        <button onClick={handleAddJob} className="px-4 py-2 bg-body text-white rounded hover:bg-green-400 text-poetsen">
                            <FontAwesomeIcon icon={faPlusCircle} className='mr-2' />Add Flow
                        </button>
                    </div>
                </>)}
            </div>
            <div className='mt-10'>
                <h2 className='text-xl text-poetsen'><FontAwesomeIcon icon={faClock} className='mr-2 text-primary' />Scheduled Flows</h2>
                <Table
                    data={jobs}
                    columns={columns}
                    onDelete={handleDelete}
                    actions={[
                        { label: 'Delete', icon: <FontAwesomeIcon icon={faTrash} />, onClick: handleDelete },
                    ]}
                />
            </div>
        </div>
    );
};

export default JobManager;
