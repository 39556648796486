import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { setUser, selectUser } from '../features/user/userSlice';
import { selectSession } from '../features/session/sessionSlice';
import config from '../config/config';
import { useStytchSession } from '@stytch/react';

const AvatarSelector = () => {
  const userPic = useSelector((state) => state.user.user.pic);
  const user = useSelector(selectUser);
  const session = useSelector(selectSession);
  const dispatch = useDispatch();
  const apiURL = config.apiUrl;
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileObject, setFileObject] = useState(null);
  //const { session } = useStytchSession();


  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(URL.createObjectURL(file));
      setFileObject(file); // Store the actual file object
    }
  };

  const updateAvatar = async () => {
    if (!fileObject) { // Use `fileObject` here
      console.error('No file selected.');
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(fileObject); // Read the actual file object
    reader.onloadend = async () => {
      const base64Image = reader.result;

      try {
        const payload = {
          user_id: user._id,
          image: base64Image,
          dynamic_id: user.dynamic_id,
        };

        const config = {
          headers: {
            'Content-Type': 'application/json',
            'stytch_session_token': session.stytch_session_token,
          },
        };

        const response = await axios.post(`${apiURL}/user/profile/avatar`, payload, config);

        if (response.data && response.data.success) {
          dispatch(setUser({
            ...user, // Spread existing user details
            pic: response.data.data,
          }));

          setSelectedFile(null);
          setFileObject(null); // Reset the file object
        } else {
          console.error(response.data.message || 'Failed to upload image.');
        }
      } catch (error) {
        console.error(error.response ? error.response.data.message : error.message);
      }
    };
  };

  return (
    <div className="text-center">
      <img src={selectedFile || userPic} alt="User Avatar" className="h-24 w-24 rounded-full mx-auto border-2" />
      <div className="mt-4">
        <input type="file" accept="image/*" onChange={handleFileChange} className="mb-2" />
        {selectedFile && <button type="button" onClick={updateAvatar} className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">Update Avatar</button>}
      </div>
    </div>
  );
};

export default AvatarSelector;
