import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressBook, faArrowRightRotate, faArrowRotateForward, faBoltLightning, faCheck, faCheckCircle, faFire, faImage, faMagnifyingGlass, faPlusCircle, faXmark } from '@fortawesome/free-solid-svg-icons';
import { setCurrentProfile } from '../features/currentTool/currentProfileSlice';
import { openComponentModal } from '../features/modals/modalcomponentSlice';

function DashboardProfilesCard() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profiles = useSelector(state => state.tools.items.profiles);
  const [searchQuery, setSearchQuery] = useState('');

  const handleSetCurrentProfile = (profile) => {
    dispatch(setCurrentProfile(profile));
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  const filteredProfiles = profiles?.filter(profile => 
    profile.name.toLowerCase().includes(searchQuery) ||
    (profile.description && profile.description.toLowerCase().includes(searchQuery))
  );

  const truncateContent = (content, maxLength = 100) => {
    if (!content) return 'No content available.';
    return content.length > maxLength ? content.slice(0, maxLength) + '...' : content;
  };

  return (
    <div className='bg-primary max-h-96 overflow-auto slim-scrollbar py-2'>
      <div className='flex flex-col lg:flex-row gap-1 justify-center lg:justify-between items-center bg-primary p-3 w-full rounded mx-auto'>
        <div className='flex justify-center items-center text-xl text-poetsen font-bold'><FontAwesomeIcon icon={faAddressBook} className='mr-2' />Profiles</div>
        <div>
            
                <button onClick={()=>dispatch(openComponentModal())} className="text-poetsen ml-auto flex uppercase text-primary hover:text-white flex-col font-bold bg-body p-1 px-3 hover:bg-gray-500 rounded">
                    <span><FontAwesomeIcon icon={faPlusCircle} className='mr-2'/>Manage</span>
                </button>
                
        </div>
       
      </div>

      {profiles?.length > 0 ? (<>        
        <div className="flex flex-col justify-center">
          
          {filteredProfiles?.map((profile, index) => (
            <div key={profile.name + index} className="flex flex-col border justify-center bg-primary p-2 hover:bg-gray-500 rounded mx-10 my-2">
              <div className="text-left overflow-x-scroll">
                <div className='text-center text-lg text-inverted text-poetsen'>{profile.name}</div>
                {/*<div className='rounded'>
                  <img className='rounded-xl mx-auto shadow-xl' src={''} alt={profile.name} />
          </div>*/}
                <div className='overflow-y-scroll text-sm'>
                  Details: {truncateContent(profile.content)}
                </div>
                {/*<div className='flex flex-col justify-center relative bottom-0'>
                  <button onClick={() => {handleSetCurrentProfile(profile);}} className="bg-body text-poetsen hover:bg-gray-700 hover:text-white text-primary font-bold rounded p-2 mt-2">
                    <FontAwesomeIcon icon={faBoltLightning} className='mr-2' />SELECT PROFILE
                  </button>
                </div>*/}
              </div>
            </div>
          ))}
        </div>
      </>) : (
        <div className="flex flex-col items-center justify-center mt-5 md:mt-20 bg-primary w-full md:w-1/3 mx-auto p-5 rounded">
          <div className="text-3xl font-bold text-black mb-5">Loading Profiles...</div>
        </div>
      )}
    </div>
  );
}

export default DashboardProfilesCard;
