import React, { useEffect, useState } from 'react';
import AppRouter from './AppRouter';
import config from './config/config';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser, setUser, resetUser } from './features/user/userSlice';
import { fetchTools } from './features/tools/toolSlice';
import { fetchBoards } from './features/boards/boardSlice';
import { fetchBoardItems } from './features/boards/boardItemSlice';
import { fetchMerchItems } from './features/merch/merchSlice';
import { fetchPackages } from './features/packages/packageSlice';
import { fetchPopularNFTs } from './features/games/popularNFTsSlice';
import { fetchMiniGames } from './features/games/gamesSlice';
import { fetchBanners } from './features/games/bannersSlice';
import { useIsMobileOrTablet, useIsLaptopOrDesktop } from './utils/useScreenSize';
import { useActiveWallet } from 'thirdweb/react';
import { getUserByDynamicID, get_user } from './api/userFunctions';
import { useUser, useLogout, useAddress } from '@thirdweb-dev/react';

const app_id = config.app_id;

function deleteCookiesStartingWith(prefix) {
  const cookies = document.cookie.split(';');
  cookies.forEach(cookie => {
      let [cookieName] = cookie.split('=');
      cookieName = cookieName.trim(); // Trim whitespace
      if (cookieName.startsWith(prefix)) {
          document.cookie = cookieName + '=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
          console.log(cookieName + ' cookie has been deleted.');
      }
  });
}

function App() {
  const dispatch = useDispatch();
  const { logout, isLoading } = useLogout();
  //const activeWallet = useActiveWallet();
  //const userAddress = activeWallet?.getAccount().address;
  const isLaptopOrDesktop = useIsLaptopOrDesktop();
    const userWalletDesktop = useUser();  // Assuming this hook can be safely called regardless of device
    const userWalletMobile = useActiveWallet();  // Assuming this too

    let userAddress, userWallet;

    if (isLaptopOrDesktop) {
        // Access data for desktop
        userAddress = userWalletDesktop?.user?.address;
        userWallet = userWalletDesktop?.user?.address;
    } else {
        // Access data for mobile
        // Make sure getAccount is safe to call here; you might need additional checks
        userAddress = userWalletMobile?.getAccount()?.address;
        userWallet = userWalletMobile?.getAccount()?.address;
    }

  // Set document title
  useEffect(() => {
    document.title = config.siteTitle;
  }, []);

  // Handle user state based on active wallet changes
  useEffect(() => {
    const handleUserUpdate = async () => {
      if (userAddress) {
        const userData = await get_user(userAddress);
        console.log('User Data:', userData);
        dispatch(setUser(userData));
      } else {
        dispatch(setUser(null));
        deleteCookiesStartingWith('thirdweb_auth_token_');
      }
    };

    handleUserUpdate();
  }, [userWallet, dispatch]);

  // Fetch initial data
  useEffect(() => {
    dispatch(fetchTools());
    dispatch(fetchMerchItems());
    dispatch(fetchPackages());
    dispatch(fetchMiniGames());
    dispatch(fetchBanners());
    dispatch(fetchPopularNFTs());
    dispatch(fetchBoards());
    dispatch(fetchBoardItems(app_id));
  }, [dispatch]);

  return (
    <div className="App">
      <AppRouter />
    </div>
  );
}

export default App;
