// src/features/BYOKUserAPIKeysSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import config from '../../config/config';

const apiURL = config.apiUrl;

// Define the initial state of the BYOKUserAPIKeys data
const initialState = {
  data: {
    openai: false,
    stablediffusion: false,
    leonardo: false,
    perplexity: false,
  },
  status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
  error: null
};

// Async thunk action for fetching BYOKUserAPIKeys
export const fetchBYOKUserAPIKeys = createAsyncThunk(
  'BYOKUserAPIKeys/fetch',
  async (userId, { rejectWithValue }) => {
    try {
      const response = await fetch(`${apiURL}/byokapp/checkBYOKUserAPIKeys/${userId}`);
      if (!response.ok) {
        throw new Error('Failed to fetch');
      }
      const data = await response.json();
      console.log('user api keys data:', data);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// The slice
export const BYOKUserAPIKeysSlice = createSlice({
  name: 'BYOKUserAPIKeys',
  initialState,
  reducers: {
    updateServiceState: (state, action) => {
        const { service, newState } = action.payload;
        if (state.data.hasOwnProperty(service)) {
          state.data[service] = newState;
        }
      },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBYOKUserAPIKeys.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchBYOKUserAPIKeys.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchBYOKUserAPIKeys.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export const { updateServiceState } = BYOKUserAPIKeysSlice.actions;

export default BYOKUserAPIKeysSlice.reducer;
