import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../../config/config';

const apiURL = config.apiUrl;

export const fetchBoards = createAsyncThunk('boards/fetchBoards', async () => {
    const response = await axios.get(`${apiURL}/boards/${config.app_id}`);
    return response.data;
});

export const createBoard = createAsyncThunk('boards/createBoard', async ({ name, userId }) => {
    const response = await axios.post(`${apiURL}/boards/${config.app_id}`, { name, userId });
    return response.data;
});

const boardSlice = createSlice({
    name: 'boards',
    initialState: { boards: [], status: 'idle', error: null },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchBoards.fulfilled, (state, action) => {
                state.boards = action.payload;
                state.status = 'succeeded';
            })
            .addCase(createBoard.fulfilled, (state, action) => {
                state.boards.push(action.payload);
            })
            .addCase(fetchBoards.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchBoards.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    }
});

export default boardSlice.reducer;