import React from 'react';

const Pagination = ({ page, pageSize, onPageChange, onPageSizeChange }) => {
  const handlePageSizeChange = (event) => {
    onPageSizeChange(parseInt(event.target.value));
  };

  return (
    <div className="flex justify-between items-center py-2">
      <div>
        <label htmlFor="pageSize" className="mr-2">Show</label>
        <select
          id="pageSize"
          value={pageSize}
          onChange={handlePageSizeChange}
          className="block appearance-none w-full bg-body border border-primary hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
        >
          <option value={10}>10</option>
          <option value={25}>25</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </select>
        <label htmlFor="pageSize">records</label>
      </div>
      <div>
        <button
          onClick={() => onPageChange(page - 1)}
          disabled={page === 1}
          className="bg-primary px-3 py-1 rounded mr-2 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          Previous
        </button>
        <span>Page {page}</span>
        <button
          onClick={() => onPageChange(page + 1)}
          className="bg-primary px-3 py-1 rounded ml-2"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Pagination;
