import axios from 'axios';
import config from '../../config/config';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const apiURL = config.apiUrl;

// Async thunk for fetching tool data
export const fetchMiniGames = createAsyncThunk('miniGames/fetchMiniGames', async () => {
    const response = await axios.get(`${apiURL}/fetch/MiniGame`);
    return response.data.data;
  });

  const miniGamesSlice = createSlice({
    name: 'miniGames',
    initialState: {
      games: [],
      status: 'idle', // 'idle', 'loading', 'succeeded', 'failed'
      error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchMiniGames.pending, (state, action) => {
          state.status = 'loading';
        })
        .addCase(fetchMiniGames.fulfilled, (state, action) => {
          state.status = 'succeeded';
          // Add any fetched games to the array
          state.games = action.payload;
        })
        .addCase(fetchMiniGames.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.error.message;
        });
    },
  });
  
  export default miniGamesSlice.reducer;