import React from 'react';
import { Link } from 'react-router-dom';
import IframeComponent from '../components/IFrameComponent';

function Chart() {
  return (<>
  <div className="flex flex-col w-2/3 rounded mx-auto">
    <IframeComponent src="https://www.dextools.io/widget-chart/en/base/pe-light/0xff5375bd65056dbe6119256fc3be2eb0ffa8a840?theme=light&chartType=2&chartResolution=30&drawingToolbars=false" title="Chart for $ANDY on Base"/>
  </div>


  </>);
}

export default Chart;