// src/components/DashboardCard.js
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import AddBoardItemForm from './AddBoardItemForm';

const DashAddBoardItem = () => {
    const boards = useSelector(state => state.boards.boards); // Get boards from the store
    const [selectedBoardId, setSelectedBoardId] = useState('');

    useEffect(() => {
        if (boards.length > 0) {
            setSelectedBoardId(boards[0]._id); // Set the first board as the default selected board
        }
    }, [boards]);

    const handleBoardChange = (event) => {
        setSelectedBoardId(event.target.value);
    };

    const selectedBoard = boards.find(board => board._id === selectedBoardId);

    return (
        <div className="bg-primary rounded p-4 shadow-lg mb-4 h-96">
            <h2 className="text-xl font-bold mb-4 text-poetsen">Add New Board Item</h2>
            <div className="mb-4">
                <label htmlFor="board" className="block text-sm font-medium">Select Board</label>
                <select
                    value={selectedBoardId}
                    onChange={handleBoardChange}
                    className="border rounded px-4 py-2 w-full mb-2 text-black"
                >
                    {boards.map(board => (
                        <option key={board._id} value={board._id}>{board.name}</option>
                    ))}
                </select>
            </div>
            {selectedBoardId && (
                <AddBoardItemForm boardId={selectedBoardId} onFormToggle={() => {}} />
            )}
        </div>
    );
};

export default DashAddBoardItem;
