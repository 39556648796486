import axios from 'axios';
import config from '../../config/config';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const apiURL = config.apiUrl;

// Async thunk for fetching tool data
export const fetchShillImages = createAsyncThunk('shillImages/fetchShillImages', async () => {
    const response = await axios.get(`${apiURL}/fetch/ShillImage`);
    //console.log('shill images response.data: ', response.data.data);
    return response.data.data;
  });

  const shillImagesSlice = createSlice({
    name: 'shillImages',
    initialState: {
      images: [],
      status: 'idle', // 'idle', 'loading', 'succeeded', 'failed'
      error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchShillImages.pending, (state, action) => {
          state.status = 'loading';
        })
        .addCase(fetchShillImages.fulfilled, (state, action) => {
          state.status = 'succeeded';
          // Add any fetched images to the array
          state.images = action.payload;
        })
        .addCase(fetchShillImages.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.error.message;
        });
    },
  });
  
  export default shillImagesSlice.reducer;