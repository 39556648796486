import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectSession, deleteSession } from '../features/session/sessionSlice';
import { authUser } from '../api/userFunctions';
import { setUser } from '../features/user/userSlice';
import Cookies from 'js-cookie';

const RequireAuth = ({ children }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const session = useSelector(selectSession);
    const app = useSelector(state => state.tools.items);
    //console.log('app info in require auth:', app);
    const currentUser = useSelector(state => state.user.user);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const verifyAuth = async () => {
            if (!session) {
                navigate('/login');
            } else {
                try {
                    const user = await authUser(session.stytch_session_token);
                    console.log('user in auth:', user);
                    console.log('app in auth:', app);
                    if (user.success) {
                        const userExists = app.users.some(appUser => appUser._id === user.data._id);
                        if (!userExists) {
                            Cookies.remove('stytch_session');
                            Cookies.remove('stytch_session_jwt');
                            dispatch(setUser(null));
                            dispatch(deleteSession());
                            navigate('/login');
                        } else {
                            setIsAuthenticated(true);
                            dispatch(setUser(user.data));
                        }
                    } else {
                        Cookies.remove('stytch_session');
                        Cookies.remove('stytch_session_jwt');
                        dispatch(setUser(null));
                        dispatch(deleteSession());
                        navigate('/login');
                    }
                } catch (error) {
                    Cookies.remove('stytch_session');
                    Cookies.remove('stytch_session_jwt');
                    dispatch(setUser(null));
                    dispatch(deleteSession());
                    navigate('/login');
                }
            }
            setIsLoading(false);
        };

        verifyAuth();
    }, [session, navigate]);

    if (isLoading) return <div>Loading...</div>;
    return isAuthenticated ? children : null;
};

export default RequireAuth;
