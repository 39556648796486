// features/userPrompts/userPromptsSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../../config/config';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../user/userSlice';

const apiURL = config.apiUrl;

// Async thunk to fetch prompts
export const fetchPrompts = createAsyncThunk(
    'userPrompts/fetchPrompts',
    async (userId) => {
        const response = await axios.get(`${apiURL}/byokapp/prompts/${userId}`);
        const reversedData = response.data.reverse();
        return reversedData;
    }
);

// Async thunk to add a prompt
export const addPrompt = createAsyncThunk(
  'userPrompts/addPrompt',
  async ({ userId, promptData }) => {
    const response = await axios.post(`${apiURL}/byokapp/prompts/${userId}`, promptData);
    return response.data;
  }
);

// Async thunk to delete a prompt
export const deletePrompt = createAsyncThunk(
  'userPrompts/deletePrompt',
  async ({ userId, promptId }) => {
    await axios.delete(`${apiURL}/byokapp/prompts/${userId}/${promptId}`);
    return promptId;
  }
);

export const userPromptsSlice = createSlice({
  name: 'userPrompts',
  initialState: {
    prompts: [],
    isModalOpen: false,
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
    toolType: 'all',
  },
  reducers: {
    openModal: (state, action) => {
      state.isModalOpen = true;
      state.toolType = action.payload; // Use the payload to set the toolType
    },
    closeModal: (state) => {
      state.isModalOpen = false;
      state.toolType = null; // Optionally reset toolType when closing the modal
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPrompts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPrompts.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.prompts = action.payload;
      })
      .addCase(fetchPrompts.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(addPrompt.fulfilled, (state, action) => {
        state.prompts.push(action.payload);
      })
      .addCase(deletePrompt.fulfilled, (state, action) => {
        state.prompts = state.prompts.filter((prompt) => prompt._id !== action.payload);
      });
  },
});

export const { openModal, closeModal } = userPromptsSlice.actions;

export const selectAllPrompts = (state) => state.userPrompts.prompts;
export const selectModalOpen = (state) => state.userPrompts.isModalOpen;
export const selectPromptsStatus = (state) => state.userPrompts.status;
export const selectPromptsError = (state) => state.userPrompts.error;

export default userPromptsSlice.reducer;
