import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchShillImages } from '../features/shillImages/shillImageSlice';
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faCopy } from '@fortawesome/free-solid-svg-icons';
import { copyToClipboard } from '../utils/commons';
import { addToast } from '../features/ui/uiSlice';

function ShillShack() {

    const dispatch = useDispatch();
    const galleryRef = useRef(null);

    useEffect(() => {
        dispatch(fetchShillImages());
    }, [dispatch]);

    const imagesArray = useSelector((state) => state.shillImages.images);

    const images = imagesArray.map(image => ({
        original: image.url
      }));

    const handleCopy = async (text) => {
      const isCopied = await copyToClipboard(text);
      if (isCopied) {
          dispatch(addToast('Copied to clipboard!', 'success'));
      } else {
          dispatch(addToast('Failed to copy!', 'error'));
      }
    };

    const scrollToGallery = () => {
      galleryRef.current.scrollIntoView({ behavior: 'smooth' });
    };

  return (<>
    <h1 className="text-center text-3xl font-bold text-poetsen mb-2">Shill Shack</h1>
    <p className="text-center text-sm md:text-lg font-semibold text-primary mb-4 w-full md:w-1/2 mx-auto">The Shill Shack is a collection of memes, tips, and other fun content created by the Andy on Base community.</p>
    <div className='w-full flex items-center justify-center'>
      <button onClick={scrollToGallery} className="bg-blue-500 hover:bg-blue-700 text-white py-1 px-4 rounded mb-4">Jump to Images</button>
    </div>
    <div className='w-full lg:w-2/3 xl:w-1/2 mx-auto bg-yellow-400 p-1 md:p-3 pb-4 rounded mb-4 border border-gray-900'>
      <h1 className="text-center text-xl text-poetsen mb-2 text-blue-500">Andy's Shilling Tips</h1>
      <div className='flex flex-col md:mx-4 text-black gap-3'>
        <div className='flex flex-row items-start'>
          <FontAwesomeIcon icon={faArrowRight} className='text-xl text-blue-500 mr-2 mt-1' />
          Use the Shill Shack images to promote Andy on Base on social media.
        </div>
        <div className='flex flex-row items-start'>
          <FontAwesomeIcon icon={faArrowRight} className='text-xl text-blue-500 mr-2 mt-1' />
          <div className='flex flex-col gap-2'>
            Include one or more links or references, such as: 
            <div className='w-fit cursor-pointer px-2 py-1 border border-gray-900 rounded' onClick={() => handleCopy('andytoken.fun')}>andytoken.fun<FontAwesomeIcon icon={faCopy} className='ml-2' /></div>
            <div className='w-fit cursor-pointer px-2 py-1 border border-gray-900 rounded' onClick={() => handleCopy('andys.place')}>andys.place<FontAwesomeIcon icon={faCopy} className='ml-2' /></div>
            <div className='w-fit cursor-pointer px-2 py-1 border border-gray-900 rounded' onClick={() => handleCopy('@_AndyOnBase')}>@_AndyOnBase<FontAwesomeIcon icon={faCopy} className='ml-2' /></div>
            <div className='w-fit cursor-pointer px-2 py-1 border border-gray-900 rounded' onClick={() => handleCopy('t.me/AndyOnBasePortal')}>t.me/AndyOnBasePortal<FontAwesomeIcon icon={faCopy} className='ml-2' /></div>
            
          </div>
        </div>
        <div className='flex flex-row items-start'>
          <FontAwesomeIcon icon={faArrowRight} className='text-xl text-blue-500 mr-2 mt-1' />
          <div>
            Include the contract address if applicable to your message.
            <span className='cursor-pointer px-2 py-1 border border-gray-900 rounded ml-2 mt-1' onClick={() => handleCopy('0x18A8BD1fe17A1BB9FFB39eCD83E9489cfD17a022')}>Copy Address<FontAwesomeIcon icon={faCopy} className='ml-2' /></span>
          </div>
          
        </div>
        <div className='flex flex-row items-start'>
          <FontAwesomeIcon icon={faArrowRight} className='text-xl text-blue-500 mr-2 mt-1' />
          Comment with something unique to minimise the possibility of being marked as spam. Don't repeat the same message over and over.
        </div>
        <div className='flex flex-row items-start'>
          <FontAwesomeIcon icon={faArrowRight} className='text-xl text-blue-500 mr-2 mt-1' />
          <div className='flex flex-col gap-2'>
          Use relevant hashtags to reach a wider audience. Some examples include: 
            <div className='w-fit cursor-pointer px-2 py-1 border border-gray-900 rounded' onClick={() => handleCopy('#AndyOnBase')}>#AndyOnBase<FontAwesomeIcon icon={faCopy} className='ml-2' /></div>
            <div className='w-fit cursor-pointer px-2 py-1 border border-gray-900 rounded' onClick={() => handleCopy('#BasedAndy')}>#BasedAndy<FontAwesomeIcon icon={faCopy} className='ml-2' /></div>
            <div className='w-fit cursor-pointer px-2 py-1 border border-gray-900 rounded' onClick={() => handleCopy('#BASEChain')}>#BASEChain<FontAwesomeIcon icon={faCopy} className='ml-2' /></div>
            <div className='w-fit cursor-pointer px-2 py-1 border border-gray-900 rounded' onClick={() => handleCopy('#Memecoin')}>#Memecoin<FontAwesomeIcon icon={faCopy} className='ml-2' /></div>
            <div className='w-fit cursor-pointer px-2 py-1 border border-gray-900 rounded' onClick={() => handleCopy('#100x')}>#100x<FontAwesomeIcon icon={faCopy} className='ml-2' /></div>
            <div className='w-fit cursor-pointer px-2 py-1 border border-gray-900 rounded' onClick={() => handleCopy('#1000x')}>#1000x<FontAwesomeIcon icon={faCopy} className='ml-2' /></div>
            <div className='w-fit cursor-pointer px-2 py-1 border border-gray-900 rounded' onClick={() => handleCopy('#Altcoin')}>#Altcoin<FontAwesomeIcon icon={faCopy} className='ml-2' /></div>
            <div className='w-fit cursor-pointer px-2 py-1 border border-gray-900 rounded' onClick={() => handleCopy('#Crypto')}>#Crypto<FontAwesomeIcon icon={faCopy} className='ml-2' /></div>
          </div>
        </div>
        <div className='flex flex-row items-start'>
          <FontAwesomeIcon icon={faArrowRight} className='text-xl text-blue-500 mr-2 mt-1' />
          <div className=''>
            If Andy's chart looks good, use a screenshot of the chart to show the price action. <a className='underline' href='https://dexscreener.com/base/0xff5375bd65056dbe6119256fc3be2eb0ffa8a840' target='_blank' alt='Andy on Base Chart'>Check the chart here.</a>
          </div>
        </div>
      </div>
    </div>

    <p className="text-center text-sm md:text-lg font-semibold text-primary mb-8 w-full md:w-1/2 mx-auto">To save an image, right click on the image and select 'Save Image As'. Once saved, use the image in your posts and marketing materials.</p>
    <div ref={galleryRef} className="flex flex-col w-2/3 rounded mx-auto">
      <ImageGallery items={images} />
    </div>
  </>);
}

export default ShillShack;