import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function MiniBook() {



    return (
        <div>
        <h1>Mini Book</h1>
        </div>
    );
}

export default MiniBook;