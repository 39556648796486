import axios from 'axios';
import config from '../config/config';

const flowsApiUrl = config.flowsApiUrl;

// Get all jobs
export const getJobs = async () => {
  try {
    const response = await axios.get(`${flowsApiUrl}/jobs`);
    return response.data;
  } catch (error) {
    console.error('Error fetching jobs:', error);
    throw error;
  }
};

// Add a new job
export const addJob = async (jobData) => {
  try {
    const response = await axios.post(`${flowsApiUrl}/jobs`, jobData);
    return response.data;
  } catch (error) {
    console.error('Error adding job:', error);
    throw error;
  }
};

// Delete a job
export const deleteJob = async (jobId) => {
  try {
    const response = await axios.delete(`${flowsApiUrl}/jobs/${jobId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting job:', error);
    throw error;
  }
};

// Update a job
export const updateJob = async (jobId, jobData) => {
  try {
    const response = await axios.put(`${flowsApiUrl}/jobs/${jobId}`, jobData);
    return response.data;
  } catch (error) {
    console.error('Error updating job:', error);
    throw error;
  }
};