// components/HamburgerMenu.js

import React from 'react';
import { useDispatch } from 'react-redux';
import { toggle } from '../features/mobile/menuSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHamburger } from '@fortawesome/free-solid-svg-icons';

const HamburgerMenu = () => {
  const dispatch = useDispatch();

  return (
    <button onClick={() => dispatch(toggle())} className="p-2">
      <FontAwesomeIcon icon={faHamburger} className='text-primary text-4xl' />
    </button>
  );
};

export default HamburgerMenu;
