import React from 'react';

const SearchBar = ({ value, onChange }) => {
  return (
    <div className="relative text-gray-600 w-full text-gray-900">
      <input
        type="search"
        name="search"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder="Search"
        className="bg-white h-10 px-5 pr-10 rounded-full text-sm focus:outline-none w-full border border-gray-400"
      />
      <button type="submit" className="absolute right-0 top-0 mt-3 mr-4">
        <svg
          className="h-4 w-4 fill-current"
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          id="Capa_1"
          x="0px"
          y="0px"
          viewBox="0 0 56.966 56.966"
          style={{ enableBackground: 'new 0 0 56.966 56.966' }}
          xmlSpace="preserve"
          width="512px"
          height="512px"
        >
          <path d="M55.146,51.887L41.588,38.329c3.486-4.021,5.615-9.245,5.615-14.829C47.203,10.505,36.698,0,23.601,0  S0,10.505,0,23.601s10.505,23.601,23.601,23.601c5.584,0,10.808-2.129,14.829-5.615l13.558,13.558  c0.536,0.536,1.245,0.805,1.954,0.805s1.418-0.268,1.954-0.805C56.218,54.724,56.218,52.96,55.146,51.887z M23.601,42.203  c-10.246,0-18.602-8.356-18.602-18.602S13.355,5,23.601,5s18.602,8.356,18.602,18.602S33.847,42.203,23.601,42.203z" />
        </svg>
      </button>
    </div>
  );
};

export default SearchBar;
