import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet';
import config from '../config/config';
import { faCirclePlay } from '@fortawesome/free-regular-svg-icons';
import FunBanner from '../components/FunBanner';

function Fun() {
    const navigate = useNavigate();
    const games = useSelector((state) => state.miniGames.games);
    
    const [filteredGames, setFilteredGames] = useState(games);
    const [typeFilter, setTypeFilter] = useState('All');
    const [categoryFilter, setCategoryFilter] = useState('All');

    useEffect(() => {
        const filtered = games.filter(game => 
            (typeFilter === 'All' || game.type === typeFilter) &&
            (categoryFilter === 'All' || game.category === categoryFilter)
        );
        setFilteredGames(filtered);
    }, [typeFilter, categoryFilter, games]);

    const types = ['All', ...new Set(games.map(game => game.type))];
    const categories = ['All', ...new Set(games.map(game => game.category))];

    return (<>
        <Helmet>
            <title>Andy's Fun Place - Cool Stuff That's Free</title>
            <meta name="description" content="Join Andy's memecoin party and play some fun games and check out other cool stuff. It's pretty sweet, man." />
            <meta name="keywords" content="ANDY memecoin, Andy's Place, crypto token, cryptocurrency, blockchain, AI characters, crypto merch, memecoin community, digital currency, crypto investment, Andy token, blockchain technology" />
            <link rel="icon" type="image/png" href={config.favicon} />
            <link rel="canonical" href={config.app_url} />
            <meta property="og:title" content="Andy's Fun Place - Cool Stuff That's Free" />
            <meta property="og:description" content="Join Andy's memecoin party and play some fun games and check out other cool stuff. It's pretty sweet, man." />
            <meta property="og:image" content={config.favicon} />
            <meta property="og:url" content={config.app_url} />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content={config.siteName} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content="Andy's Fun Place - Cool Stuff That's Free"/>
            <meta name="twitter:description" content="Join Andy's memecoin party and play some fun games and check out other cool stuff. It's pretty sweet, man." />
            <meta name="twitter:image" content={config.favicon} />
            <meta name="twitter:site" content={config.twitter} />
            <meta name="twitter:creator" content={config.twitter} />
          </Helmet>
        <div className='lg:-mt-5'><FunBanner size={'1000x200'} /></div>
        <div className="p-4 pt-0 lg:pt-3">
            <div className='flex flex-col lg:flex-row items-center lg:items-end justify-center lg:justify-between'>
                <h1 className="text-3xl font-bold mb-6 text-poetsen text-primary">Andy's Games</h1>
                <div className="flex flex-col gap-1 items-center justify-center">
                    <div className=''>
                        Filter by type:
                    </div>
                    <div className="mb-4 flex gap-2 flex-wrap items-center justify-center">
                        {types.map(type => (
                            <button
                                key={type}
                                onClick={() => setTypeFilter(type)}
                                className={`capitalize px-4 py-2 rounded ${typeFilter === type ? 'border border-blue-500 bg-blue-500 text-white' : 'border border-gray-900 bg-yellow-400 text-black'}`}
                            >
                                {type}
                            </button>
                        ))}
                    </div>
                </div>
                <div className="flex flex-col gap-1 items-center justify-center">
                    <div className=''>
                        Filter by category:
                    </div>
                    <div className="mb-4 flex gap-2 flex-wrap items-center justify-center">
                        {categories.map(category => (
                            <button
                                key={category}
                                onClick={() => setCategoryFilter(category)}
                                className={`capitalize px-4 py-2 rounded ${categoryFilter === category ? 'border border-blue-500 bg-blue-500 text-white' : 'border border-gray-900 bg-yellow-400 text-black'}`}
                            >
                                {category}
                            </button>
                        ))}
                    </div>
                </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                {filteredGames.map((game) => (
                    <div key={game._id} className="border border-blue-500 p-4 rounded-lg shadow-lg flex flex-col items-center">
                        <img src={game.image} alt={game.name} className="w-full h-48 object-cover mb-4 rounded-md"/>
                        <h2 className="text-2xl font-semibold text-blue-500 text-center">{game.name}</h2>
                        <p className="mb-4 text-gray-500">{game.description}</p>
                        <button 
                            onClick={() => navigate(`/minigame?id=${game._id}`)}
                            className="pulse mt-auto py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
                        >
                            <FontAwesomeIcon icon={faCirclePlay} className='mr-2 text-lg' />Play Now
                        </button>
                    </div>
                ))}
            </div>
            <div className='pt-16'>
            <FunBanner size={'1920x1080'} />
            </div>
        </div>
        {/*<ModalNewsletter isOpen={modalNewsletterOpen} onClose={closeNewsletterModal} />*/}
    </>);
}

export default Fun;
