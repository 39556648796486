import io from "socket.io-client";
import config from "../config/config";

const socket = io.connect(config.apiUrl);
socket.on('connect', () => {
    //alert('Socket connected:', socket.id);
    //console.log('Socket connected:', socket.id);
});

socket.on('connect_error', (error) => {
    //alert('Connection Error Message:', error.message);
    console.log('Connection Error Message:', error.message);
});
export default socket;