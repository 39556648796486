// PromptComponent.jsx
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectUser } from '../features/user/userSlice';
import { selectSession } from '../features/session/sessionSlice';
import axios from 'axios';
import { addToast } from '../features/ui/uiSlice';
import config from '../config/config';

const SavePrompt = ({ prompt, onClose, type }) => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const session = useSelector(selectSession);
  const [promptType, setPromptType] = useState(type);
  const [tags, setTags] = useState([]);
  const [inputTag, setInputTag] = useState('');
  const apiURL = config.apiUrl;

  const handlePromptTypeChange = (e) => {
    setPromptType(e.target.value);
  };

  const handleTagInputChange = (e) => {
    setInputTag(e.target.value);
  };

  const handleAddTag = () => {
    if (inputTag.trim() !== '' && tags.length < 10) {
      setTags([...tags, inputTag.trim()]);
      setInputTag('');
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    setTags(tags.filter((tag) => tag !== tagToRemove));
  };

  const handleSave = async () => {
    try {
        const payload = {
            prompt,
            promptType,
            tags,
        };
      try {
        
        const response = await axios.post(`${apiURL}/byokapp/prompts/${user._id}`, payload,
        {
          headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'stytch_session_token': session.stytch_session_token,
          },
          //withCredentials: true
        });
        //console.log('save prompt: ', response.data);
        //return response.data;
      } catch (error) {
          //console.error('Error updating user profile', error);
      }

      // Dispatch the addToast action to notify the user of the successful save
      dispatch(addToast('Prompt saved successfully!', 'success'));

      // Reset the form
      setPromptType('text');
      setTags([]);
      setInputTag('');
      onClose();
    } catch (error) {
      console.error('Error saving prompt:', error);
      dispatch(addToast('Error saving prompt. Please try again.', 'error'));
    }
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-6">
        PROMPT TO SAVE:
      <p className="text-lg font-semibold mb-4 p-5 bg-primary rounded text-inverted">{prompt}</p>
      <div className="mb-4">
        <label htmlFor="promptType" className="block mb-2">
          Prompt Type:
        </label>
        <select
          id="promptType"
          value={promptType}
          onChange={handlePromptTypeChange}
          className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option value="text">Text Prompt</option>
          <option value="image">Image Prompt</option>
        </select>
      </div>
      <div className="mb-4">
        <label htmlFor="tags" className="block mb-2">
          Tags (up to 10):
        </label>
        <div className="flex gap-2 mb-2">
          {tags.map((tag, index) => (
            <div
              key={index}
              className="px-2 py-1 bg-gray-200 rounded-lg flex items-center"
            >
              <span className="mr-2">{tag}</span>
              <button
                type="button"
                onClick={() => handleRemoveTag(tag)}
                className="text-gray-500 hover:text-gray-700 focus:outline-none"
              >
                &times;
              </button>
            </div>
          ))}
        </div>
        <div className="flex">
          <input
            type="text"
            id="tags"
            value={inputTag}
            onChange={handleTagInputChange}
            className="w-full px-4 py-2 border rounded-l-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Enter a tag"
          />
          <button
            type="button"
            onClick={handleAddTag}
            className="px-4 py-2 bg-primary text-inverted rounded-r-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            Add
          </button>
        </div>
      </div>
      <div className="flex justify-center">
        <button
          type="button"
          onClick={handleSave}
          className="px-4 py-2 bg-primary text-inverted rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 mr-2"
        >
          Save Prompt
        </button>
      </div>
    </div>
  );
};

export default SavePrompt;