import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBoards, createBoard } from '../features/boards/boardSlice';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { selectUser } from '../features/user/userSlice';

const Board = () => {
    const dispatch = useDispatch();
    const boards = useSelector(state => state.boards.boards);
    const items = useSelector(state => state.boardItems.items);
    const boardStatus = useSelector(state => state.boards.status);
    const user = useSelector(selectUser);
    const [newBoardName, setNewBoardName] = useState('');

    useEffect(() => {
        if (boardStatus === 'idle') {
            dispatch(fetchBoards());
        }
    }, [boardStatus, dispatch]);

    const handleCreateBoard = async () => {
        if (newBoardName.trim()) {
            await dispatch(createBoard({ name: newBoardName, userId: user._id })).unwrap();
            setNewBoardName('');
        }
    };

    return (
        <div className="container mx-auto p-4 min-h-96">
            <h1 className="text-3xl font-bold mb-4 text-primary text-poetsen">Boards</h1>
            <div className="mb-4">
                <input
                    type="text"
                    placeholder="New Board Name"
                    value={newBoardName}
                    onChange={(e) => setNewBoardName(e.target.value)}
                    className="border rounded px-4 py-2 mr-2 text-black"
                />
                <button
                    onClick={handleCreateBoard}
                    className="bg-primary text-white px-4 py-2 rounded"
                >
                    Create Board
                </button>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {boards.map(board => (
                    <Link key={board._id} to={`/boards/${board._id}`} className="flex flex-col items-start gap-2 border border-primary rounded p-4 shadow hover:bg-gray-700 hover:text-white">
                        <div className='flex flex-row items-center'>
                        <FontAwesomeIcon icon={faPaperclip} className='text-primary mr-2' />
                        <h2 className="text-xl font-semibold text-poetsen">{board.name}</h2>
                        <span className="text-sm text-gray-500 ml-4">Created: {board.createdAt}</span>
                        </div>
                        {items.filter(item => item.boardId === board._id).length > 0 && (
                            
                            <span className="text-primary ml-4">Board Items: {items.filter(item => item.boardId === board._id).length}</span>
                        )}
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default Board;
