import React from 'react';
import { useLocation } from 'react-router-dom';
import IframeComponent from '../components/IFrameComponent';

function Shop() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const name = queryParams.get('name');
  const productId = queryParams.get('product_id');

  // Start with the base URL
  let url = 'https://andys-place-merch.printify.me/products';

  // Modify URL if 'productId' and 'name' are present
  if (productId && name) {
    url = `https://andys-place-merch.printify.me/product/${productId}/${name}`;
  }

  return (
    <>
      <div className="flex flex-col w-full md:w-2/3 rounded mx-auto">
        <IframeComponent src={url} title="Andy's Place Merch" />
      </div>
    </>
  );
}

export default Shop;
