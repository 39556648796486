import React from 'react';

// Utility function to truncate text
const truncateText = (text, maxLength) => {
    if (!text) return ''; // add a check for undefined or null text
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };

const Table = ({ data, columns, onDelete, actions }) => (
  <div className="overflow-x-auto">
    <table className="min-w-full bg-body">
      <thead className='text-primary'>
        <tr>
          {columns.map((col) => (
            <th key={col.accessor} className="py-2 px-4 border-b border-primary capitalize">
              {col.label}
            </th>
          ))}
          {actions && <th className="py-2 px-4 border-b border-primary">Actions</th>}
        </tr>
      </thead>
      <tbody>
        {data.map((row) => (
          <tr key={row._id}>
            {columns.map((col) => (
              <td key={col.accessor} className="py-2 px-4 border-b border-primary">
                    {truncateText(row[col.accessor], 250)}
                </td>
            ))}
            {actions && (
              <td className="py-2 px-4 border-b border-primary">
                {actions.map((action) => (
                  <button
                    key={action.label}
                    onClick={() => action.onClick(row._id)}
                    className="text-red-600 hover:text-red-800 mx-1"
                  >
                    {action.icon}
                  </button>
                ))}
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

export default Table;