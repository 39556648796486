import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentTool, selectCurrentTool } from '../features/currentTool/currentToolSlice';
import { close } from '../features/mobile/menuSlice';
import { setThreadId } from '../features/threads/threadSlice';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faTachometerAlt, faGear, } from '@fortawesome/free-solid-svg-icons';
import { fullLogout } from '../api/userFunctions';
import { selectSession, deleteSession } from '../features/session/sessionSlice';
import { logout } from '../features/user/userSlice';

const SidebarHistory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const session = useSelector(selectSession);
  const user = useSelector(state => state.user.user);
  const threads = useSelector(state => state.threadHistory.threads);
  //console.log('threads', threads);
  const tools = useSelector((state) => [
    ...(state.tools.items.tools ?? []), // Use optional chaining or default to empty array
    ...(state.tools.items.assistants ?? [])
  ].sort((a, b) => a.name.localeCompare(b.name)));
  
  const currentTool = useSelector(selectCurrentTool);
  const loading = useSelector((state) => state.tools.status) === 'loading';

  const handleLogout = async () => {
    const loggingOut = await fullLogout(session);
    dispatch(logout());
    dispatch(deleteSession());
    navigate('/login');
};

const truncateContent = (content, maxLength = 100) => {
  if (!content) return 'No content available.';
  return content.length > maxLength ? content.slice(0, maxLength) + '...' : content;
};



  return (
    <div className="text-poetsen w-80 lg:w-64 flex flex-col overflow-y-auto slim-scrollbar h-95vh">
      <div className="flex items-center justify-center space-x-4 w-full">
            <img
                  src={user?.pic}
                  alt="Avatar"
                  className="w-16 h-16 rounded-full border-2 border-primary"
                /> 
            <div className='flex flex-col justify-start leading-none'>
                {/*<div className='text-2xl text-primary font-bold text-poetsen'>Andy's Stuff</div>*/}
                <div className='text-lg'>AI Credits: {Math.round(user?.ai_credits)}</div>
                <button onClick={()=> {navigate('/dashboard'); dispatch(close());}} className="mt-1 flex items-center hover:text-primary hover:bg-gray-700 hover:text-gray-200 px-3 py-2 border border-primary rounded">
                  <FontAwesomeIcon icon={faTachometerAlt} className='text-primary' />
                  <span className="ml-2">Dashboard</span>
              </button>
            </div>
        </div>
      {/* Navigation Items */}
      <div className="text-primary text-sm flex flex-col gap-2 mt-2 lg:mt-5">
        {threads?.map((thread) => (
            <div key={thread._id} className='flex flex-col gap-1 px-2 cursor-pointer' onClick={() => {dispatch(setThreadId(thread._id)); dispatch(close()); dispatch(setCurrentTool(tools.find(tool => tool.oai_assistantId === thread.oai_assistantId)))}}>
              <div className='flex items-center justify-between'>
                {truncateContent(thread.messages[0].content, 65)}
              </div>
              <div className='flex items-center justify-between text-gray-500'>
                <img src={tools.find(tool => tool.oai_assistantId === thread.oai_assistantId)?.image_data.example_url} alt="Tool" className="w-6 h-6 rounded-full" />
                {new Date(thread.lastUpdated).toLocaleString('en-US')}
                
              </div>
              <hr className='border-primary' />
            </div>
          ))}
      </div>

      {/* User Profile */}
      {user && (
        <div className="px-5">
          {/*<img src={user.pic} alt="User" className="h-12 w-12 rounded-full mx-auto border-2 border-gray-600" />
          <h2 className="mt-2 text-center">{user.name}</h2>*/}
          <div className="mt-4 text-center">
            <Link to="/settings" onClick={() => {dispatch(close())}} className="inline-block w-full text-primary px-4 py-1 border border-primary rounded hover:bg-gray-700 focus:outline-none focus:ring">
              <FontAwesomeIcon icon={faGear} className='mr-2' />Settings
            </Link>
            <button onClick={() => {handleLogout(); dispatch(close());}} className="mt-2 flex items-center w-full text-primary border border-primary rounded hover:bg-gray-700 justify-center p-1">
                    <FontAwesomeIcon icon={faSignOutAlt} className='mr-2' />Logout
                </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SidebarHistory;
