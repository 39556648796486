import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../features/user/userSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faMapMarkerAlt, faStore, faRightToBracket, faTachometerAlt, faPaperclip, faCommentDots, faCloudArrowUp, faArrowAltCircleUp, faAngleDoubleUp, faWaveSquare } from '@fortawesome/free-solid-svg-icons';
import ThemeSwitcher from './ThemeSwitcher';
import HamburgerMenu from './HamburgerMenu';
import config from '../config/config';
import { fullLogout } from '../api/userFunctions';
import { selectSession, deleteSession } from '../features/session/sessionSlice';
import '../index.css';
import { fetchBoards } from '../features/boards/boardSlice';

const siteLogo = config.logoUrl;

const Header = () => {
    const [isTalking, setIsTalking] = useState(false);
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const session = useSelector(selectSession);
    const boards = useSelector(state => state.boards.boards);
    const boardStatus = useSelector(state => state.boards.status);
    
    const handleLogout = async () => {
        const loggingOut = await fullLogout(session);
        dispatch(logout());
        dispatch(deleteSession());
        navigate('/login');
    };

    useEffect(() => {
        if (boardStatus === 'idle') {
            dispatch(fetchBoards());
        }
    }, [boardStatus, dispatch]);

    useEffect(() => {
        const toggleTalking = () => {
            setIsTalking(true);
            setTimeout(() => {
                setIsTalking(false);
            }, 1200);
        };
        toggleTalking();
        const intervalId = setInterval(toggleTalking, 7000);
        return () => clearInterval(intervalId);
    }, []);

    const getDisplayPath = () => {
        if (pathname.startsWith('/boards/')) {
            const boardId = pathname.split('/')[2];
            const board = boards.find(b => b._id === boardId);
            return board ? `BOARDS / ${board.name.toUpperCase()}` : 'BOARDS';
        }
        return pathname.toUpperCase().substring(1) || 'HOME';
    };

    return (
        <>

            <div className="flex xl:justify-between items-center p-4 pt-1 xl:pt-2 pb-2 text-primary text-poetsen h-5vh">
                
                <div className="flex justify-start xl:justify-start xl:items-center flex-row text-primary xl:w-2/5 xl:gap-3">
                {session && (
                    <div className='mr-2 xl:mr-0 xl:hidden'>
                        <HamburgerMenu />
                    </div>
                )}
                {!session && (
                    <div onClick={() => navigate('/login')} className='xl:hidden'>
                        <FontAwesomeIcon icon={faRightToBracket} className='text-xl bg-primary text-inverted rounded-full p-3 cursor-pointer' />
                    </div>
                )}
                <div onClick={() => navigate('/')} className="cursor-pointer text-center flex flex-row items-center space-x-4">
                    <img
                        src={siteLogo}
                        alt="Avatar"
                        className={`w-8 h-auto border border-primary bg-primary rounded-full ${isTalking ? '' : ''}`}
                    />
                    <span className='text-2xl text-primary font-bold text-poetsen hidden xl:block'>{config.siteName}</span>
                    <div className='text-poetsen text-2xl mx-auto xl:hidden'>{config.siteName}</div>
                </div>
                    
                    <div className=' items-center justify-start xl:flex-row xl:justify-center hidden xl:flex'>
                        <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" />
                        <div className='hidden xl:block'>{'/ ' + getDisplayPath()}</div>
                    </div>
                    
                    
                        
                        
                </div>

                
                <div className='flex flex-col justify-end items-end space-y-2 w-full pr-6'>
                    {session && (
                        <div className="flex-row items-center space-x-4 justify-end hidden xl:flex">
                            <button onClick={() => navigate('/dashboard')} className="flex items-center hover:text-gray-500">
                                <FontAwesomeIcon icon={faTachometerAlt} />
                                <span className="ml-2">Dashboard</span>
                            </button>
                            <button onClick={() => navigate('/')} className="flex items-center hover:text-gray-500">
                                <FontAwesomeIcon icon={faCommentDots} />
                                <span className="ml-2">Chat</span>
                            </button>
                            <button onClick={() => navigate('/boards')} className="flex items-center hover:text-gray-500">
                                <FontAwesomeIcon icon={faPaperclip} />
                                <span className="ml-2">Boards</span>
                            </button>
                            <button onClick={() => navigate('/magicflows')} className="flex items-center hover:text-gray-500">
                                <FontAwesomeIcon icon={faWaveSquare} />
                                <span className="ml-2">Flows</span>
                            </button>
                            <Link to='/uploadables' className="flex items-center hover:text-gray-500">
                                <FontAwesomeIcon icon={faCloudArrowUp} />
                                <span className="ml-2">Uploadables</span>
                            </Link>
                            <ThemeSwitcher />
                            {session && (
                                <button onClick={() => handleLogout()} className="flex cursor-pointer items-center mr-10 text-primary hover:text-gray-500 xl:text-inverted rounded-full p-2 py-0 xl:p-1 xl:bg-primary">
                                    <FontAwesomeIcon icon={faSignOutAlt} />
                                </button>
                            )}
                        </div>
                    )}
                    
                </div>
            </div>
        </>
    );
};

export default Header;
