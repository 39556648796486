import { createSelector } from '@reduxjs/toolkit';

// Existing imports remain here
import axios from 'axios';
import config from '../../config/config';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const apiURL = config.apiUrl;

// Async thunk for fetching tool data
export const fetchPopularNFTs = createAsyncThunk('popularNFTs/fetchPopularNFTs', async () => {
    const response = await axios.get(`${apiURL}/fetch/PopularNFT`);
    return response.data.data;
});

const popularNFTsSlice = createSlice({
    name: 'popularNFTs',
    initialState: {
      nfts: [],
      status: 'idle', // 'idle', 'loading', 'succeeded', 'failed'
      error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchPopularNFTs.pending, (state, action) => {
          state.status = 'loading';
        })
        .addCase(fetchPopularNFTs.fulfilled, (state, action) => {
          state.status = 'succeeded';
          // Corrected property from 'games' to 'nfts'
          state.nfts = action.payload;
        })
        .addCase(fetchPopularNFTs.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.error.message;
        });
    },
});

// Selector
export const selectAllPopularNFTs = state => state.popularNFTs.nfts;

// If you need a more complex selector, use createSelector from Reselect (already a dependency of Redux Toolkit)
export const getNFTsStatus = createSelector(
  [state => state.popularNFTs.status],
  (status) => status
);

export default popularNFTsSlice.reducer;
