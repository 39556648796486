import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useContract, useNFT } from "@thirdweb-dev/react";
import { useActiveWallet } from 'thirdweb/react';
import { getAllWalletNFTs } from '../api/userFunctions';
import { setCurrentNFTChat } from '../features/currentTool/currentNFTChatSlice';
import ConnectButtonMobileComponent from '../components/ConnectButtonMobile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightRotate, faImage, faMagnifyingGlass, faXmark } from '@fortawesome/free-solid-svg-icons';
import { setNFTs, selectNFTs } from '../features/user/userNFTsSlice';
import NFTSearchForm from '../components/NFTSearchForm';

function NFTChat() {
  const userWallet = useActiveWallet();
  const userAddress = userWallet?.getAccount()?.address;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [contractAddress, setContractAddress] = useState('');
  const [tokenId, setTokenId] = useState(null);
  const { contract } = useContract(contractAddress);
  const { data: nft, isLoading, error } = useNFT(contract, tokenId);

  const [showNFTSearchForm, setShowNFTSearchForm] = useState(false);
  const allNFTs = useSelector(selectNFTs);
  const [searchQuery, setSearchQuery] = useState('');

  const chain = userWallet?.getChain();

  const fetchAllNFTs = async () => {
    const fetchedNFTS = await getAllWalletNFTs(userAddress, userWallet?.getChain().id);
    dispatch(setNFTs(fetchedNFTS.data.ownedNfts));
  }

  const handleSetCurrentNFT = (nft) => {
    dispatch(setCurrentNFTChat(nft));
  };

  const clearNFTs = () => {
    dispatch(setNFTs([]));
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  const filteredNFTs = allNFTs.filter(nft => 
    nft.name.toLowerCase().includes(searchQuery) ||
    (nft.description && nft.description.toLowerCase().includes(searchQuery))
  );

  return (
    <div className='min-h-screen'>
      <div className='flex flex-col md:flex-row gap-2 md:gap-5 justify-center items-center p-3 pb-0 w-full md:w-1/2 rounded mx-auto text-poetsen'>
        {userAddress && (
          <>
            
            <button onClick={fetchAllNFTs} className="flex uppercase mb-2 md:mb-0 flex-col bg-primary p-3 px-6 hover:bg-gray-700 hover:text-white rounded">
              {allNFTs?.length > 0 ? <span><FontAwesomeIcon icon={faArrowRightRotate} className='mr-2' />Refresh My NFTs</span> : <span><FontAwesomeIcon icon={faImage} className='mr-2' />Display My NFTs</span>}
            </button>
          </>
        )}
        <ConnectButtonMobileComponent />
        {allNFTs?.length > 0 && (
          <button onClick={clearNFTs} className="flex uppercase flex-col bg-primary p-3 px-6 hover:bg-gray-700 hover:text-white rounded">
            <span><FontAwesomeIcon icon={faXmark} className='mr-2' />Clear NFTs</span>
          </button>
        )}
      </div>

      {allNFTs?.length > 0 ? (<>
        <div className='flex flex-col justify-center items-center mt-5'>
          <div className='flex flex-col md:flex-row gap-2 md:gap-5 justify-center items-center bg-primary p-3 w-full md:w-1/2 rounded mx-auto'>
          <input
              type="text"
              placeholder="Search My NFTs"
              onChange={handleSearchChange}
              value={searchQuery}
              className="text-black p-2 rounded flex-grow w-full md:w-1/4"
            />
            {/*<button onClick={() => setShowNFTSearchForm(!showNFTSearchForm)} className="flex w-full md:w-1/3 uppercase mb-2 md:mb-0 flex-col font-bold bg-body p-2 px-6 hover:bg-gray-700 hover:text-white rounded">
              <span>{!showNFTSearchForm ? (<><FontAwesomeIcon icon={faMagnifyingGlass} className='mr-2' /> Search Other NFTs</>) : (<><FontAwesomeIcon icon={faXmark} className='mr-2' /> Close Search Box</>)}</span>
      </button>*/}
          </div>
          {/*{showNFTSearchForm && (
            <div className='flex flex-col justify-center items-center mt-5'>
              <NFTSearchForm />
            </div>
          )}*/}
        </div>
        <div className="flex flex-col md:flex-row md:flex-wrap justify-center mt-5">
          
          {filteredNFTs.map((nft, index) => (
            <div key={nft.tokenId + index} className="flex flex-col w-5/6 md:w-1/5 bg-primary p-3 px-6 hover:bg-gray-500 text-inverted rounded mx-auto md:mx-2 my-2">
              <div className="text-left overflow-x-scroll">
                <div className='text-center text-2xl text-white font-bold mb-3 text-poetsen'>{nft.name || nft.contract.name}</div>
                <div className='rounded'>
                  <img className='rounded mx-auto shadow-xl' src={nft.image.thumbnailUrl} alt={nft.name || nft.contract.name} />
                </div>
                <div className='h-20 mt-5 overflow-y-scroll text-sm'>
                  Description: {nft.description || nft.contract.description}
                </div>
                <div className='flex flex-col justify-center relative bottom-0 text-poetsen'>
                  <button onClick={() => {handleSetCurrentNFT(nft); navigate('/')}} className="bg-body hover:bg-gray-700 hover:text-white text-primary rounded p-2 mt-2">
                    CHAT WITH THIS NFT
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </>) : (
        <div className="flex flex-col items-center justify-center mt-5 md:mt-20 bg-primary w-full md:w-1/3 mx-auto p-5 rounded">
          <div className="text-3xl font-bold text-black mb-5 text-poetsen">Ready to chat with your NFTs?</div>
          <div className="text-lg text-black w-full">Please connect your wallet and ensure you have NFTs in your wallet. Click the DISPLAY MY NFTS button to fetch the NFTs in your wallet. You can then choose which NFT you would like to chat with.</div>
          <div className='mt-6'>
            <ConnectButtonMobileComponent />
          </div>
        </div>
      )}
    </div>
  );
}

export default NFTChat;
