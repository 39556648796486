// src/features/currentNFTChat/currentNFTChatSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const currentNFTChatSlice = createSlice({
  name: 'currentNFTChat',
  initialState: {
    value: null, // Initial state of the current tool is null
  },
  reducers: {
    // Action to update the current tool
    setCurrentNFTChat: (state, action) => {
      state.value = action.payload;
    },
    // Action to clear the current tool (optional)
    clearCurrentNFTChat: (state) => {
      state.value = null;
    },
  },
});

// Export the actions
export const { setCurrentNFTChat, clearCurrentNFTChat } = currentNFTChatSlice.actions;

// Export the selector to access the current tool state
export const selectCurrentNFTChat = (state) => state.currentNFTChat.value;

// Export the reducer
export default currentNFTChatSlice.reducer;
