import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useContract, useNFT } from "@thirdweb-dev/react";
import { getAllWalletNFTs } from '../api/userFunctions';
import { setCurrentNFTChat } from '../features/currentTool/currentNFTChatSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightRotate, faArrowRotateForward, faFire, faImage, faMagnifyingGlass, faXmark } from '@fortawesome/free-solid-svg-icons';
import { setNFTs, selectNFTs } from '../features/user/userNFTsSlice';
import NFTSearchForm from '../components/NFTSearchForm';
import { selectAllPopularNFTs } from '../features/games/popularNFTsSlice';

function BrowseNFTsPage() {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [contractAddress, setContractAddress] = useState('');
  const [tokenId, setTokenId] = useState(null);
  const { contract } = useContract(contractAddress);
  const { data: nft, isLoading, error } = useNFT(contract, tokenId);

  const [showNFTSearchForm, setShowNFTSearchForm] = useState(false);
  const [allNFTs, setAllNFTs] = useState([]);
  const allRawNFTs = useSelector(selectAllPopularNFTs);
  const [searchQuery, setSearchQuery] = useState('');

  function shuffleArray(array) {
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  }

  useEffect(() => {
    if (allRawNFTs.length > 0) {
      setAllNFTs(shuffleArray(allRawNFTs));
    }
  }, [allRawNFTs]);

  const handleShuffleClick = () => {
    setAllNFTs(shuffleArray(allRawNFTs));
  };

  const handleSetCurrentNFT = (nft) => {
    dispatch(setCurrentNFTChat(nft));
  };

  const clearNFTs = () => {
    dispatch(setNFTs([]));
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  const filteredNFTs = allNFTs?.filter(nft => 
    nft.name.toLowerCase().includes(searchQuery) ||
    (nft.description && nft.description.toLowerCase().includes(searchQuery))
  );

  return (
    <div className='min-h-screen bg-primary'>
      <div className='flex flex-col gap-2 justify-center items-center bg-primary p-3 w-full xl:w-2/3 rounded mx-auto'>
        <div className='flex text-3xl text-poetsen text-white font-bold'><FontAwesomeIcon icon={faFire} className='text-orange-400 mr-2' />Chat with Popular NFTs</div>
        <div className='w-full md:w-2/3 lg:mb-4 font-bold lg:text-lg'>
            You don't have to own the most popular NFTs to chat with them! Just choose your favorite from the list below or search for any NFT by contract address and token ID.
        </div>
       
      </div>

      {allNFTs?.length > 0 ? (<>
        <div className='flex flex-col justify-center items-center'>
          <div className='flex flex-col md:flex-row gap-2 md:gap-5 justify-center items-center bg-primary p-3 w-full lg:w-2/3 rounded mx-auto'>
          <input
              type="text"
              placeholder="Search Popular NFTs"
              onChange={handleSearchChange}
              value={searchQuery}
              className="text-black p-2 rounded flex-grow w-full md:w-1/4"
            />
            <button onClick={() => setShowNFTSearchForm(!showNFTSearchForm)} className="text-poetsen flex uppercase md:mb-0 flex-col font-bold bg-body p-2 px-6 hover:bg-gray-500 hover:text-white rounded">
              <span>{!showNFTSearchForm ? (<><FontAwesomeIcon icon={faMagnifyingGlass} className='mr-2' /> Search by Contract/Token</>) : (<><FontAwesomeIcon icon={faXmark} className='mr-2' /> Close Search Box</>)}</span>
            </button>
            {allNFTs?.length > 0 && (
                <button onClick={handleShuffleClick} className="text-poetsen mx-auto flex uppercase flex-col font-bold bg-body p-2 px-6 hover:bg-gray-500 hover:text-white rounded">
                    <span><FontAwesomeIcon icon={faArrowRotateForward} className='mr-2' />Show New NFTs</span>
                </button>
                )}
          </div>
          {showNFTSearchForm && (
            <div className='flex w-full flex-col justify-center items-center mt-0 lg:mt-5'>
              <NFTSearchForm />
            </div>
          )}
        </div>
        
        <div className="flex flex-col md:flex-row md:flex-wrap justify-center lg:mt-5">
          
          {filteredNFTs?.map((nft, index) => (
            <div key={nft.tokenId + index} className="flex flex-col border justify-center w-5/6 md:w-1/3 lg:w-1/6 bg-primary p-3 px-6 hover:bg-gray-500 text-inverted rounded mx-auto md:mx-2 my-2">
              <div className="text-left overflow-x-scroll">
                <div className='text-center text-2xl text-poetsen text-white font-bold mb-3'>{nft.name || nft.contract.name}</div>
                <div className='rounded'>
                  <img className='rounded-xl mx-auto shadow-xl' src={nft.image.thumbnailUrl} alt={nft.name || nft.contract.name} />
                </div>
                <div className='h-20 mt-5 overflow-y-scroll text-sm'>
                  Description: {nft.description || nft.contract.description}
                </div>
                <div className='flex flex-col justify-center relative bottom-0'>
                  <button onClick={() => {handleSetCurrentNFT(nft); navigate('/')}} className="bg-body text-poetsen hover:bg-gray-700 hover:text-white text-primary font-bold rounded p-2 mt-2">
                    CHAT WITH THIS NFT
                  </button>
                  <div className='text-sm text-center mt-2 font-bold'>Token ID: {nft.tokenId}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </>) : (
        <div className="flex flex-col items-center justify-center mt-5 md:mt-20 bg-primary w-full md:w-1/3 mx-auto p-5 rounded">
          <div className="text-3xl font-bold text-black mb-5">Loading NFTs...</div>
        </div>
      )}
    </div>
  );
}

export default BrowseNFTsPage;
