import React, { useState, useEffect } from 'react';
import config from '../config/config';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector, useDispatch } from 'react-redux';
import { selectUser, setUser } from '../features/user/userSlice';
import { selectCurrentTool } from '../features/currentTool/currentToolSlice';
import { addToast } from '../features/ui/uiSlice';
import { faStar as solidStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as regularStar } from '@fortawesome/free-regular-svg-icons';

const CurrentToolCard = () => {
    const apiURL = config.apiUrl;
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const currentTool = useSelector(selectCurrentTool);
    const [isFavorite, setIsFavorite] = useState(
        user.favorites?.generators?.includes(currentTool._id)
      );

    useEffect(() => {
        setIsFavorite(user.favorites?.generators?.includes(currentTool._id));
    }, [user, currentTool]);

    const toggleFavorite = async () => {
        let updatedFavorites;
        const newFavoriteStatus = !isFavorite;
        setIsFavorite(newFavoriteStatus);
        try {
            await axios.post(`${apiURL}/favorites/update`, {
                dynamic_id: user.dynamic_id,
                item_id: currentTool._id,
                type: 'generators',
                isFavorite: newFavoriteStatus,
            });
            dispatch(addToast(newFavoriteStatus ? 'Favorite Added!' : 'Favorite Removed!', 'success'));

            if (newFavoriteStatus) {
                updatedFavorites = {
                    ...user.favorites,
                    ['generators']: [...user.favorites['generators'], currentTool._id],
                };

            } else {
                updatedFavorites = {
                    ...user.favorites,
                    ['generators']: user.favorites['generators'].filter((id) => id !== currentTool._id),
                };
            }
            const updatedUser = {
                ...user,
                favorites: updatedFavorites,
            };
            dispatch(setUser(updatedUser));
          } catch (error) {
            setIsFavorite(!newFavoriteStatus);
            dispatch(addToast('Favorite NOT updated! Please try again.', 'error'));
          }
      };

      return (<>
        <div className="relative w-full p-4 pl-8 pr-8 bg-white shadow-md rounded-lg">
            <div className="flex items-center space-x-4 mb-4"> {/* Add mb-4 for spacing between the content and the instructions */}
                <div className="flex-shrink-0">
                <img src={currentTool.image_data.example_url} alt={currentTool.name} className="h-16 w-16 rounded-full object-cover border" />
                </div>
                <div className="flex-grow">
                <h2 className="text-lg font-semibold text-black">{currentTool.name}</h2>
                <p className="text-gray-600">{currentTool.description}</p>
                </div>
                <button onClick={toggleFavorite} className="absolute bottom-0 right-0 p-2">
                    <FontAwesomeIcon icon={isFavorite ? solidStar : regularStar} className="text-yellow-500 text-2xl"/>
                </button>
            </div>
        </div>
        <div className="p-2 border rounded mt-2">
            <span className='font-bold text-primary'>Instructions: </span>{currentTool.description}
        </div>
      </>);
    };

export default CurrentToolCard;
